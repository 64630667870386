import withRouter from 'found/withRouter'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import PropTypes from 'prop-types'
import React, { useState, useContext, useEffect } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'
import { Colours } from '@thebeansgroup/sb-ui/style_helpers'

import ImplementationContext from '@src/contexts/implementation'
import { useIsExpressConnect } from '@src/hooks/use_is_express_connect'

import Button from '@components/button'
import PoweredBy from '@components/powered_by'
import RegistrationTime from '@components/registration_time'
import { handleHasDocumentStorageAccess } from '@helpers/auth'
import { brandColourDefaultProp, isConsumerGroupStudent } from '@helpers/index'
import { useEditorData } from '@hooks/use_editor_context'
import { useFlagshipEvent } from '@hooks/use_flagship_event'

import { handleClick, openAuthUrl, getButtonText } from './helpers'
import * as styles from './styles'

const AuthButton = (props) => {
  const implementation = useContext(ImplementationContext)
  const { t, consumerGroup } = props

  const isExpress = useIsExpressConnect(props.match)

  const [buttonColour, setButtonColour] = useState(props.buttonColour)

  const [buttonTextColour, setButtonTextColour] = useState(
    props.buttonTextColour
  )

  const [bodyTextColour, setBodyTextColour] = useState(props.textColour)

  const { editorState } = useEditorData()
  const { sendFlagshipEvent } = useFlagshipEvent()

  useEffect(() => {
    if (editorState) {
      setButtonColour(editorState.colourState?.button?.value)
      setButtonTextColour(editorState.colourState?.buttonText?.value)
      setBodyTextColour(editorState.colourState?.bodyText?.value)
    }
  }, [editorState])

  const renderButton = () => {
    return (
      <Button
        buttonColour={buttonColour}
        buttonTextColour={buttonTextColour}
        connectOfferPage={props.connectOfferPage}
        onClick={() =>
          handleClick(
            handleHasDocumentStorageAccess,
            () => openAuthUrl(props, implementation, true, isExpress),
            () => openAuthUrl(props, implementation, false, isExpress),
            sendFlagshipEvent
          )
        }
      >
        {getButtonText(t, consumerGroup)}
      </Button>
    )
  }

  const renderRegistrationTime = () => {
    if (!props.hasRegistrationTime) return null

    if (!isConsumerGroupStudent(consumerGroup)) return null

    return <RegistrationTime textColour={bodyTextColour} />
  }

  const renderPoweredBy = () => {
    if (!props.hasPoweredBy) return null

    return (
      <div css={styles.poweredBy}>
        <PoweredBy
          consumerGroup={consumerGroup}
          colour={bodyTextColour}
        />
      </div>
    )
  }

  return (
    <div css={styles.wrapper(props.variant)}>
      {renderButton()}
      {renderPoweredBy()}
      {renderRegistrationTime()}
    </div>
  )
}

AuthButton.propTypes = {
  buttonColour: PropTypes.string,
  buttonTextColour: PropTypes.string,
  connectOfferPage: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  consumerGroup: PropTypes.string.isRequired,
  hideLogo: PropTypes.bool,
  isMultiOffer: PropTypes.bool,
  isMultiCCG: PropTypes.bool,
  country: PropTypes.string.isRequired,
  isExpress: PropTypes.bool.isRequired,
  hasPoweredBy: PropTypes.bool,
  hasRegistrationTime: PropTypes.bool,
  variant: PropTypes.bool
}

AuthButton.defaultProps = {
  buttonColour: brandColourDefaultProp(),
  buttonTextColour: Colours('white', 300),
  hideLogo: false,
  isMultiOffer: false,
  isMultiCCG: false,
  hasRegistrationTime: false,
  hasPoweredBy: false,
  variant: false
}

export default withLDConsumer()(
  createFragmentContainer(withRouter(withI18n(AuthButton)), {
    connectOfferPage: graphql`
      fragment authButton_connectOfferPage on ConnectOfferPage {
        ...button_connectOfferPage
        clientId
        offer {
          title
          consumerGroup
        }
        brand {
          logo
          name
        }
      }
    `
  })
)
