/**
 * @generated SignedSource<<9fa111e8f89f6887515cedae984c4e0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "standardWrapper_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "brandImages_brand"
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "fa89382e00e8c0a0b6f90ae5e5f7de5d";

module.exports = node;
