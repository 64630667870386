import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  wrapper: {
    width: '100%' // Fixes IE flexbox bug
  },
  list: {
    textAlign: 'left',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 10px',
    listStyleType: 'none',
    marginBottom: '0'
  },
  listItemMarker: {
    fontWeight: 'bold',
    marginRight: '6px'
  }
})

export default styles
