import * as Sentry from '@sentry/browser'

import { track } from '@src/tracker'

import {
  getPropFromUrl,
  isDomainWhitelisted,
  getStbOfferPath
} from '@helpers/url'

import { MESSAGE } from '../constants'

export function trackOnPageLoad(uid, domains) {
  if (shouldTrackMissingDomain(domains)) trackInvalidDomain(uid)
}

export function trackInvalidDomain(uid) {
  const url = getPropFromUrl(getStbOfferPath(), 'origin')
  track(MESSAGE, 'page_load', uid, url)
  Sentry.captureException(new Error(`${MESSAGE} | ${url} | ${uid}`))
}

export function shouldTrackMissingDomain(domains) {
  const stbOfferPath = getStbOfferPath()
  return !!(
    Array.isArray(domains) &&
    stbOfferPath &&
    !isDomainWhitelisted(stbOfferPath, domains)
  )
}
