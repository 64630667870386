import { getActiveOffers, getArrayOfOffersCCGs } from '../../../helpers'

export const getOfferContext = (props) => {
  if (!props?.offer) return null

  return {
    activeRedemptionTypeId: props?.offer?.activeRedemptionTypeId,
    boosted: props?.offer?.boosted,
    boostEndDate: props?.offer?.baseRedemptionType?.boostEndDate,
    boostStartDate: props?.offer?.baseRedemptionType?.boostStartDate,
    brandName: props?.offer?.brand?.name,
    brandSlug: props?.offer?.brand?.slug,
    brandUid: props?.offer?.brand?.brandId,
    consumerGroup: props?.offer?.consumerGroup,
    countryCode: props.countryCode,
    offerEndDate: props?.offer?.endDate,
    offerStartDate: props?.offer?.startDate,
    offerSlug: props?.offer?.slug,
    offerTitle: props?.offer?.title,
    offerUid: props?.offer?.uid,
    primaryCategoryName: props?.offer?.primaryCategory?.name,
    primaryCategoryUid: props?.offer?.primaryCategory?.categoryId,
    redemptionClass: props?.offer?.redemptionClass,
    redemptionMethod: props?.offer?.redemptionMethod,
    redemptionType: props?.offer?.redemptionType
  }
}

export const getMultiCCGContext = (props, selectedOffer) => {
  if (selectedOffer || !props?.isMultiCCGConnect) return null

  return {
    brandName: props?.offer?.brand?.name,
    brandSlug: props?.offer?.brand?.slug,
    brandUid: props?.offer?.brand?.brandId,
    consumerGroups: getOfferConsumerGroups(props.connectOfferPage),
    countryCode: props.countryCode,
    primaryCategoryName: props?.offer?.primaryCategory?.name,
    primaryCategoryUid: props?.offer?.primaryCategory?.categoryId
  }
}

export const getSelectedOffer = (props) => {
  if (!props?.isMultiCCGConnect) return props

  if (!props?.selectedOfferId) return null

  const selectedOffer = props.connectOfferPage.offers.filter(
    (offer) => offer.uid === props.selectedOfferId
  )

  if (selectedOffer.length) {
    return {
      countryCode: props.countryCode,
      offer: {
        ...selectedOffer[0]
      }
    }
  }

  return null
}

export const getOfferConsumerGroups = (connectOfferPage) => {
  const activeOffers = getActiveOffers(connectOfferPage.offers)
  const consumerGroupsOnOffer = getArrayOfOffersCCGs(activeOffers)

  return consumerGroupsOnOffer
}
