import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { baby } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const wrapper = css`
  margin-top: 16px;
  margin-bottom: 24px;
  min-width: 310px;

  ${baby} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

export const blurredCode = () => css`
  color: transparent;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  border: 2px dashed;
  border-color: ${Colours('lavender', 500)};
  user-select: none;
  border-radius: 6px;
  padding: 8px 4px;
  width: 100%;
  font-size: 16px;
  margin-bottom: 8px;
`
