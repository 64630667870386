import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import Issuance from '@thebeansgroup/issuance-library/build/issuance'
import ThirdPartyTracking from '@thebeansgroup/third_party_tracking'

import {
  CODE_PAGE,
  CODE_REVEAL_PAGE,
  OFFER_PAGE
} from '@src/constants/impression_tracking'
import { offerIsNoCode } from '@src/helpers/issuance'
import environment from '@src/relay'
import { trackIssuance } from '@src/tracker'

import AuthButton from '@components/auth_button'
import CodeBlock from '@components/code_block'
import ExpiredOfferAlert from '@components/expired_offer_alert'
import IssuanceButton from '@components/issuance_button'
import IssuanceErrorAlert from '@components/issuance_error_alert'
import LoadingIssuance from '@components/loading_issuance'
import NoCodesAlert from '@components/no_codes_alert'
import { getAffiliateLink } from '@helpers/issuance'

import { ERRORS } from './constants'
import { getOfferUrl, isPreCodePage } from './helpers'
import * as styles from './styles'

class StandardIssuance extends React.Component {
  constructor(props) {
    super(props)

    this.bindHandlers()
  }

  bindHandlers() {
    this.renderError = this.renderError.bind(this)
    this.handleError = this.handleError.bind(this)
    this.renderHasNotIssued = this.renderHasNotIssued.bind(this)
    this.renderLoggedOut = this.renderLoggedOut.bind(this)
    this.renderHasIssued = this.renderHasIssued.bind(this)
    this.handleIssuance = this.handleIssuance.bind(this)
    this.renderIsLoadingIssuance = this.renderIsLoadingIssuance.bind(this)
  }

  getCodeClick(issueCode) {
    issueCode()
  }

  renderHasNotIssued(issueCode) {
    this.props.setIsLoggedIn(true)

    if (isPreCodePage()) {
      this.props.setPage(CODE_REVEAL_PAGE)
      ThirdPartyTracking.sessionReplay('?page=pre_code')
    }

    return (
      <IssuanceButton
        onClick={this.getCodeClick.bind(this, issueCode)}
        connectOfferPage={this.props?.connectOfferPage}
        isExpress={this.props.isExpress}
        isNoCode={offerIsNoCode(this.props?.offer)}
        shouldDisplayNewTemplate={this.props.shouldDisplayNewTemplate}
        {...this.props?.templateConfig?.button}
      />
    )
  }

  handleIssuance(issuance) {
    const { offer, brand, country, viewer } = this.props
    trackIssuance({
      offer,
      brand,
      country,
      isLoggedIn: viewer?.isLoggedIn,
      issuance,
      affiliateLink: getAffiliateLink(this.props, issuance?.affiliateLink),
      email: viewer?.userProfile?.email
    })
    if (this.props?.handleIssuance) this.props.handleIssuance(issuance)
  }

  renderLoggedOut() {
    const { country } = this.props
    const { consumerGroup } = this.props?.offer
    this.props.setIsLoggedIn(false)
    this.props.setPage(OFFER_PAGE)

    ThirdPartyTracking.sessionReplay('?page=offer')

    return (
      <div css={styles.wrapper}>
        <AuthButton
          connectOfferPage={this.props?.connectOfferPage}
          {...this.props?.templateConfig?.button}
          hideLogo={this.props.hideLogo}
          consumerGroup={consumerGroup}
          isMultiCCG={this.props?.isMultiCCG}
          isMultiOffer={this.props?.isMultiOffer}
          country={country}
          isExpress={this.props?.isExpress}
          hasPoweredBy
          hasRegistrationTime
        />
      </div>
    )
  }

  handleError(issuanceState, error) {
    if (this.props?.handleCodeIssuanceError) {
      this.props.handleCodeIssuanceError(error)
    }
    return this.renderError(issuanceState, error)
  }

  renderError(issuanceState, error) {
    if (error === ERRORS.EXPIRED) return <ExpiredOfferAlert />

    if (error === ERRORS.NO_CODES) return <NoCodesAlert />

    return <IssuanceErrorAlert />
  }

  renderIsLoadingIssuance() {
    return <LoadingIssuance offer={this.props?.offer} />
  }

  renderHasIssued(issuedState) {
    const { code, link, endDate } = issuedState
    this.props.setPage(CODE_PAGE)

    ThirdPartyTracking.sessionReplay('?page=code')

    return (
      <CodeBlock
        code={code}
        consumerGroup={this.props?.offer?.consumerGroup}
        country={this?.props?.country}
        link={link}
        endDate={endDate}
        onCopyCodeClick={this?.props?.onCopyCodeClick}
        onHasIssued={this?.props?.onHasIssued}
        brand={this?.props?.brand}
        offer={this?.props?.offer}
        connectOfferPage={this?.props?.connectOfferPage}
        showCodePrompt={this?.props?.showCodePrompt}
      />
    )
  }

  reportError(error) {
    Sentry.captureException(error)
  }

  render() {
    return (
      <Issuance
        environment={environment}
        offerUid={this.props?.offer?.uid}
        renderHasNotIssued={this.renderHasNotIssued}
        renderLoggedOut={this.renderLoggedOut}
        renderError={this.handleError}
        renderHasIssued={this.renderHasIssued}
        renderIsLoadingIssuance={this.renderIsLoadingIssuance}
        reportError={this.reportError}
        offerUrl={getOfferUrl()}
        onIssuance={this.handleIssuance}
        affiliateLinkOverride={getAffiliateLink(this.props)}
        shouldIssueInstoreOffersInASingleWindow={
          this.props?.shouldIssueInstoreOffersInASingleWindow
        }
        shouldIssueOnlineOffersInASingleWindow={
          this.props?.shouldIssueOnlineOffersInASingleWindow
        }
        parentQueryString={this.props?.match?.location.query.host_page_params}
        shouldOpenLoggedOutAffiliateLink={
          !this?.props?.offer?.restrictAffiliateLink
        }
      />
    )
  }
}

StandardIssuance.defaultProps = {
  hideLogo: false,
  showCodePrompt: true,
  isExpress: false,
  shouldDisplayNewTemplate: false,
  setIsLoggedIn: () => {},
  setPage: () => {}
}

StandardIssuance.propTypes = {
  viewer: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  connectOfferPage: PropTypes.object.isRequired,
  templateConfig: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  shouldIssueInstoreOffersInASingleWindow: PropTypes.bool,
  shouldIssueOnlineOffersInASingleWindow: PropTypes.bool,
  hideLogo: PropTypes.bool,
  isExpress: PropTypes.bool,
  onCopyCodeClick: PropTypes.func,
  handleCodeIssuanceError: PropTypes.func,
  handleIssuance: PropTypes.func,
  onHasIssued: PropTypes.func,
  showCodePrompt: PropTypes.bool,
  isMultiCCG: PropTypes.bool,
  isMultiOffer: PropTypes.bool,
  setIsLoggedIn: PropTypes.func,
  setPage: PropTypes.func,
  shouldDisplayNewTemplate: PropTypes.bool
}

export default createFragmentContainer(StandardIssuance, {
  viewer: graphql`
    fragment standardIssuance_viewer on AccountsViewer {
      isLoggedIn
      userProfile {
        email
      }
    }
  `,
  connectOfferPage: graphql`
    fragment standardIssuance_connectOfferPage on ConnectOfferPage {
      ...authButton_connectOfferPage
      ...codeBlock_connectOfferPage
      ...issuanceButton_connectOfferPage
      customOfferData {
        issuanceOverrideUrl
      }
    }
  `,
  offer: graphql`
    fragment standardIssuance_offer on Offer {
      ...codeBlock_offer
      uid
      boosted
      endDate
      offerId
      restrictAffiliateLink
      primaryCategory {
        categoryId
        name
      }
      redemptionClass
      redemptionMethod
      redemptionType
      slug
      startDate
      title
      uid
      verified
      consumerGroup
    }
  `,
  brand: graphql`
    fragment standardIssuance_brand on Brand {
      ...codeBlock_brand
      brandId
      name
      slug
    }
  `
})
