import {
  ANDROID_APP_STORE_URL,
  IOS_APP_STORE_URL
} from '@src/constants/app_download'
import { unstructured } from '@src/tracker'

import {
  ANDROID,
  APP_STORE_IMAGES,
  LANG_KEYS,
  TRANSLATION_KEYS
} from './constants'

export const getAppLinkData = (country, platform) => {
  const isAndroid = platform === ANDROID
  const href = getHref(country, isAndroid)
  const src = getSrc(country, platform)
  const translationFallback = getTranslationFallback(isAndroid)

  return { href, src, translationFallback }
}

export const buildAppStoreLink = (country, platformUrl) => {
  const countryCode = country === 'uk' ? 'gb' : country?.toLowerCase()
  return platformUrl.replace('{countryCode}', countryCode)
}

export const getHref = (country, isAndroid) => {
  if (isAndroid) return buildAppStoreLink(country, ANDROID_APP_STORE_URL)

  return buildAppStoreLink(country, IOS_APP_STORE_URL)
}

export const getSrc = (country, platform) => {
  const lang = LANG_KEYS[country] ?? LANG_KEYS.uk
  return APP_STORE_IMAGES[lang][platform]
}

export const getTranslationFallback = (isAndroid) => {
  return isAndroid
    ? TRANSLATION_KEYS.androidFallback
    : TRANSLATION_KEYS.iosFallback
}

export const trackAppLinkClick = (platform, country, locationOnPage) => {
  unstructured(
    'iglu:com.studentbeans/app_download_link_click/jsonschema/1-0-0',
    {
      app_store: platform,
      country_code: country,
      location_on_page: locationOnPage
    }
  )
}
