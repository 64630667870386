import { StyleSheet } from 'aphrodite'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { baby } from '@thebeansgroup/sb-ui/style_helpers/queries'

const placeHolderShimmer = {
  '0%': {
    backgroundPosition: '-468px 0'
  },
  '100%': {
    backgroundPosition: '468px 0'
  }
}

const styles = StyleSheet.create({
  loadingBlock: {
    position: 'relative',
    height: '40px',
    width: '309px',
    backgroundColor: Colours('grey', 100),
    borderRadius: '5px',
    marginTop: '16px',
    marginBottom: '8px',
    backgroundImage: `linear-gradient(to right, ${Colours(
      'grey',
      100
    )} 0%, #FFF 20%, ${Colours('grey', 100)} 40%, ${Colours(
      'grey',
      100
    )} 100%)`,
    animationName: [placeHolderShimmer],
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    animationFillMode: 'forwards',
    animationTimingFunction: 'linear',

    ...baby({
      marginTop: '20px',
      marginBottom: '20px'
    })
  },
  loadingBlockTop: {
    height: '20px',
    marginTop: '16px',
    marginBottom: '0'
  },
  loadingBlockBottom: {
    height: '80px',
    display: 'flex',
    justifyContent: 'center'
  },
  loadingBlockBottomContentContainer: {
    marginTop: '10px'
  },
  loadingBlockBottomContent: {
    height: '10px',
    width: '290px',
    backgroundColor: Colours('grey', 200),
    margin: '8px auto',
    backgroundImage: `linear-gradient(to right, ${Colours(
      'grey',
      200
    )} 0%, #FFF 20%, ${Colours('grey', 200)} 40%, ${Colours('grey', 200)} 100%)`
  }
})

export default styles
