import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import BrandImages from '@src/components/brand_images'
import { BEANS_BRAND_STUDENT_BEANS } from '@src/constants'

import CodeReady from '@components/code_ready'
import Footer from '@components/footer'
import { getBackgroundColour } from '@helpers/index'
import { useEditorData } from '@hooks/use_editor_context'

import { trackCCGContentSquare } from './helpers'
import styles from './styles'

const StandardWrapper = (props) => {
  const [backgroundColour, setBackgroundColour] = useState(
    getBackgroundColour(props.templateConfig)
  )

  const { editorState } = useEditorData()

  useEffect(() => {
    if (editorState) {
      setBackgroundColour(editorState.colourState?.background?.value)
    }
  }, [editorState])

  useEffect(() => {
    trackCCGContentSquare(props.offer?.closedConsumerGroup)
  }, [props.offer?.closedConsumerGroup])

  const renderImages = () => (
    <BrandImages
      brand={props.brand}
      templateConfig={props.templateConfig}
    />
  )

  const renderContent = () => {
    if (props.shouldDisplayNewTemplate) {
      return (
        <Fragment>
          <CodeReady />
          <div
            data-testid='card'
            className={css(styles.base.card)}
          >
            {props.children}
          </div>
        </Fragment>
      )
    }

    return props.children
  }

  return (
    <div
      data-testid='backgroundBase'
      className={css(
        styles.wrapper(backgroundColour).base,
        backgroundColour && styles.wrapper(backgroundColour).background
      )}
    >
      {renderImages()}
      <div className={css(styles.base.main)}>
        <div className={css(styles.base.content)}>{renderContent()}</div>
        <Footer
          viewer={props.viewer}
          country={props.country}
          onLogout={props.onLogout}
          hideLogo={props.hideLogo}
          offer={props.offer}
          beansBrand={props.beansBrand}
        />
      </div>
    </div>
  )
}

StandardWrapper.defaultProps = {
  hideLogo: false,
  beansBrand: BEANS_BRAND_STUDENT_BEANS,
  shouldDisplayNewTemplate: false
}

StandardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  viewer: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  templateConfig: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  hideLogo: PropTypes.bool,
  beansBrand: PropTypes.string,
  shouldDisplayNewTemplate: PropTypes.bool
}

export default createFragmentContainer(StandardWrapper, {
  viewer: graphql`
    fragment standardWrapper_viewer on AccountsViewer {
      ...footer_viewer
    }
  `,
  brand: graphql`
    fragment standardWrapper_brand on Brand {
      ...brandImages_brand
    }
  `,
  offer: graphql`
    fragment standardWrapper_offer on Offer {
      closedConsumerGroup
      ...footer_offer
    }
  `
})
