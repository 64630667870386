/**
 * @generated SignedSource<<0948d40787a25104e75bce99f0666fda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "codeBlock_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "redemptionType",
      "storageKey": null
    }
  ],
  "type": "Offer",
  "abstractKey": null
};

node.hash = "cae0b63a80d1af3d4c79a05a7b4a1e15";

module.exports = node;
