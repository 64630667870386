import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import AppDownloadBanner from '@components/app_download_banner'
import Code from '@components/code'
import CopyCodePrompt from '@components/copy_code_prompt'
import NextCodeAlert from '@components/next_code_alert'
import { getBrandName } from '@helpers/brand'
import { getAffiliateLink } from '@helpers/issuance'

import { shouldRenderNextCodeAlert } from './helpers'
import styles from './styles'

const CodeBlock = (props) => {
  const renderNextCodeAlert = () => {
    if (!shouldRenderNextCodeAlert(props?.offer?.redemptionType)) return null

    return <NextCodeAlert endDate={props?.endDate} />
  }

  const renderCodePrompt = () => {
    if (!props?.showCodePrompt) return null

    return (
      <CopyCodePrompt
        link={getAffiliateLink(props, props?.link)}
        brand={getBrandName(props?.brand)}
      />
    )
  }

  return (
    <div
      className={css(styles.base.issued)}
      role='region'
    >
      {renderCodePrompt()}
      <Code
        code={props?.code}
        connectOfferPage={props?.connectOfferPage}
        onCopyCodeClick={props?.onCopyCodeClick}
        onHasIssued={props?.onHasIssued}
      />
      {renderNextCodeAlert()}
      <AppDownloadBanner
        country={props?.country}
        consumerGroup={props?.consumerGroup}
      />
    </div>
  )
}

CodeBlock.defaultProps = {
  showCodePrompt: true
}

CodeBlock.propTypes = {
  code: PropTypes.string.isRequired,
  consumerGroup: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  brand: PropTypes.object.isRequired,
  connectOfferPage: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  onCopyCodeClick: PropTypes.func,
  onHasIssued: PropTypes.func,
  showCodePrompt: PropTypes.bool
}

export default createFragmentContainer(CodeBlock, {
  connectOfferPage: graphql`
    fragment codeBlock_connectOfferPage on ConnectOfferPage {
      ...code_connectOfferPage
      customOfferData {
        issuanceOverrideUrl
      }
    }
  `,
  offer: graphql`
    fragment codeBlock_offer on Offer {
      redemptionType
    }
  `,
  brand: graphql`
    fragment codeBlock_brand on Brand {
      name
    }
  `
})
