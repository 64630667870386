import appleStoreLogoEn from '@images/app-apple-store-en.svg'
import googlePlayLogoEn from '@images/app-google-play-en.png'

export const ANDROID = 'android'
export const IOS = 'ios'

export const LANG_KEYS = {
  uk: 'en'
}

export const APP_STORE_IMAGES = {
  en: {
    android: googlePlayLogoEn,
    ios: appleStoreLogoEn
  }
}

export const TRANSLATION_KEYS = {
  androidFallback: 'Get it on Google Play',
  iosFallback: 'Download on the App Store'
}
