import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'
import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Subhead from '@thebeansgroup/ui_typography/subhead'

import { getLogo, getText, handleContainerDirection } from './helpers'
import * as styles from './styles'

const PoweredBy = ({ consumerGroup, t, colour }) => {
  const logo = getLogo(consumerGroup)
  const text = getText(t)
  const containerDirection = handleContainerDirection(text)

  return (
    <div css={styles.container(containerDirection)}>
      <Subhead colour={colour}>{text}</Subhead>
      <img
        src={logo.src}
        css={styles.logo}
        alt={logo.altText}
      />
    </div>
  )
}

PoweredBy.defaultProps = {
  consumerGroup: 'student',
  colour: Colours('grey', 500)
}

PoweredBy.propTypes = {
  consumerGroup: PropTypes.string,
  colour: PropTypes.string,
  t: PropTypes.func.isRequired
}

export default withI18n(PoweredBy)
