import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import ImageHeader from '@components/image_header'
import { getBrandName } from '@helpers/brand'
import { getDesktopImage, getMobileImage } from '@helpers/index'
import { useEditorData } from '@hooks/use_editor_context'

import styles from './styles'

const BrandImages = ({ templateConfig, brand, variant }) => {
  const [desktopImage, setDesktopImage] = useState(
    getDesktopImage(templateConfig)
  )
  const [mobileImage, setMobileImage] = useState(getMobileImage(templateConfig))

  const { editorState } = useEditorData()

  useEffect(() => {
    if (editorState) {
      setDesktopImage(
        editorState.imageState?.desktopImage?.value ??
          getDesktopImage(templateConfig)
      )
      setMobileImage(
        editorState.imageState?.mobileImage?.value ??
          getMobileImage(templateConfig)
      )
    }
  }, [editorState, templateConfig, desktopImage])

  const renderMobileImage = () => {
    if (!mobileImage) return null

    if (variant) return null

    return (
      <div className={css(styles.base.mobileImage)}>
        <ImageHeader
          imageSrc={mobileImage}
          imageHeight={'84px'}
          imageWidth={'450px'}
          imageDescription={getBrandName(brand)}
        />
      </div>
    )
  }

  const renderDesktopImage = () => {
    if (!desktopImage) return null

    return (
      <div className={css(styles.base.desktopImage)}>
        <img
          className={css(styles.base.image)}
          height='100%'
          src={desktopImage}
          aria-label={getBrandName(brand)}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderMobileImage()}
      {renderDesktopImage()}
    </React.Fragment>
  )
}

BrandImages.defaultProps = {
  variant: false
}

BrandImages.propTypes = {
  brand: PropTypes.object.isRequired,
  templateConfig: PropTypes.object.isRequired,
  variant: PropTypes.bool
}

export default createFragmentContainer(BrandImages, {
  brand: graphql`
    fragment brandImages_brand on Brand {
      name
    }
  `
})
