/**
 * @generated SignedSource<<829f2af6dbdc37f3d8c6e9500f0ad5c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "offerPageContentWrapper_connectOfferPage",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "offerErrorHandler_connectOfferPage"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "offerPageViewTracker_connectOfferPage"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "pageHead_connectOfferPage"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domains",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "offerPageViewTracker_offer"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "consumerGroup",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ConnectOfferPage",
  "abstractKey": null
};

node.hash = "f4d7af5a6728d8c97b1ec3f11a9c8178";

module.exports = node;
