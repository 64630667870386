import { StyleSheet } from 'aphrodite'

import {
  mother,
  brother,
  sister
} from '@thebeansgroup/sb-ui/style_helpers/queries'

import { EMBEDDED_PAGE_HEIGHT } from '@helpers/implementation/constants'

const base = StyleSheet.create({
  desktopImage: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '50%',
    maxHeight: EMBEDDED_PAGE_HEIGHT,
    height: EMBEDDED_PAGE_HEIGHT,
    minHeight: EMBEDDED_PAGE_HEIGHT,
    overflow: 'hidden',

    ...mother({
      flexGrow: 0,
      flexBasis: '600px'
    }),

    ...sister({
      display: 'none'
    }).andBelow()
  },

  mobileImage: {
    ...brother({
      display: 'none'
    }).andAbove()
  },

  image: {
    maxWidth: 'none'
  }
})

const image = (src) =>
  StyleSheet.create({
    base: {
      backgroundImage: `url("${src}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }
  })

export default {
  base,
  image
}
