import { css } from '@emotion/react'

export const appBlockImage = css`
  display: inline-block;
  margin-right: 8px;
  line-height: 0;

  img {
    width: 100px;
    height: auto;
  }
`
