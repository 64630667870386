/**
 * @generated SignedSource<<4f06b8f6be521d90d304f6b30a505368>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "standardIntro_offer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "offerTitle_offer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "offerDescription_offer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "offerSubtitle_offer"
    }
  ],
  "type": "Offer",
  "abstractKey": null
};

node.hash = "99b1a325049608471205c3ceb13f0d35";

module.exports = node;
