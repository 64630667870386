import { StyleSheet } from 'aphrodite'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

const styles = StyleSheet.create({
  container: {
    maxWidth: '355px',
    margin: '0 auto 8px'
  },
  code: {
    color: Colours('grey', 500),
    fontWeight: 600,
    border: '2px dashed',
    borderColor: Colours('violet', 200),
    borderRadius: '4px',
    padding: '8px 12px',
    minWidth: '309px',
    maxWidth: '327px',
    width: '100%',
    fontSize: '18px',
    wordBreak: 'break-all',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '24px',
    letterSpacing: '5px'
  },
  button: {
    background: Colours('violet', 500),
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    minWidth: '309px',
    maxWidth: '327px',
    marginTop: '8px',
    maxHeight: '48px',
    padding: '8px 4px'
  },
  codeCopied: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    padding: '3px'
  },
  codeText: {
    lineHeight: '18px',
    height: '17px'
  }
})

export default styles
