import { css } from '@emotion/react'

import {
  brotherAndAbove,
  baby
} from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const wrapper = css`
  margin-top: 28px;
  margin-bottom: 16px;
  width: 100%;

  ${baby} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

export const mobileTitle = css`
  display: block;

  ${brotherAndAbove} {
    display: none;
  }
`

export const desktopTitle = css`
  display: none;

  ${brotherAndAbove} {
    display: block;
  }
`

export const preCodeTitleDesktop = css`
  text-align: left;
  display: none;

  ${brotherAndAbove} {
    display: block;
  }
`

export const preCodeTitleMobile = css`
  text-align: left;
  display: block;

  ${brotherAndAbove} {
    display: none;
  }
`
