import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import PageViewTracker from '@components/page_view_tracker'
import { OFFER_PAGE_TYPE } from '@constants/offer_page'

import {
  getOfferContext,
  getSelectedOffer,
  getMultiCCGContext
} from './helpers'

const OfferPageViewTracker = (props) => {
  const selectedOffer = getSelectedOffer(props)

  return (
    <PageViewTracker
      onLoad={props.onLoad}
      viewer={props.viewer}
      connectOfferPage={props.connectOfferPage}
      error={props.error}
      offerContext={getOfferContext(selectedOffer)}
      multiCCGContext={getMultiCCGContext(props, selectedOffer)}
      brandName={props?.offer?.brand?.name}
      categoryName={props?.offer?.primaryCategory?.name}
      pageType={OFFER_PAGE_TYPE}
    />
  )
}

OfferPageViewTracker.propTypes = {
  viewer: PropTypes.object,
  error: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.number
  }),
  countryCode: PropTypes.string,
  onLoad: PropTypes.func,
  offer: PropTypes.object
}

export default createFragmentContainer(OfferPageViewTracker, {
  viewer: graphql`
    fragment offerPageViewTracker_viewer on AccountsViewer {
      ...pageViewTracker_viewer
    }
  `,
  connectOfferPage: graphql`
    fragment offerPageViewTracker_connectOfferPage on ConnectOfferPage {
      ...pageViewTracker_connectOfferPage
      offers {
        activeRedemptionTypeId
        boosted
        baseRedemptionType {
          boostEndDate
          boostStartDate
        }
        brand {
          name
          slug
          brandId
        }
        consumerGroup
        enabled
        endDate
        startDate
        status
        slug
        title
        uid
        primaryCategory {
          categoryId
          name
        }
        redemptionClass
        redemptionMethod
        redemptionType
        primaryCategory {
          name
        }
      }
    }
  `,
  offer: graphql`
    fragment offerPageViewTracker_offer on Offer {
      activeRedemptionTypeId
      boosted
      baseRedemptionType {
        boostEndDate
        boostStartDate
      }
      brand {
        name
        slug
        brandId
      }
      consumerGroup
      endDate
      startDate
      slug
      title
      uid
      primaryCategory {
        categoryId
        name
      }
      redemptionClass
      redemptionMethod
      redemptionType
      primaryCategory {
        name
      }
    }
  `
})
