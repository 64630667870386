import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'

import ErrorAlert from '@components/error_alert'

const ExpiredOfferAlert = ({ t }) => (
  <ErrorAlert
    title={t('d_youre_too_late')}
    body={t('m_sorry_discount_expired')}
  />
)

ExpiredOfferAlert.propTypes = {
  t: PropTypes.func.isRequired
}

export default withI18n(ExpiredOfferAlert)
