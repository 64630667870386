import { isConsumerGroupGraduate, isConsumerGroupStudent } from '@src/helpers'

import { LOGOS, POWERED_BY_FALLBACK } from './constants'

export const getLogo = (consumerGroup) => {
  if (
    isConsumerGroupGraduate(consumerGroup) ||
    isConsumerGroupStudent(consumerGroup)
  ) {
    return LOGOS[consumerGroup]
  }

  return LOGOS.beansId
}

export const getText = (t) => {
  return t('m_powered_by_SB') || POWERED_BY_FALLBACK
}

export const handleContainerDirection = (text) => {
  if (text.length > 24) return 'column'

  return 'row'
}
