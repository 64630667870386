import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'
import Title2 from '@thebeansgroup/ui_typography/title2'

import { getTitle } from './helpers'
import * as styles from './styles'

const CodeReady = ({ t }) => {
  return (
    <div css={styles.title}>
      <Title2
        isMobile
        component='h2'
      >
        {getTitle(t)}
      </Title2>
    </div>
  )
}

CodeReady.propTypes = {
  t: PropTypes.func.isRequired
}

export default withI18n(CodeReady)
