import { getDefaultOfferTermsRoute } from '@src/routing/helpers'

import { getUrlQueryString } from '@helpers/url'

import { SEE_DISCOUNT, SEE_TERMS } from './constants'

export const getTermsUrl = (props, implementation) => {
  const params = {
    slug: props.slug,
    country: props.country,
    implementation
  }
  return getDefaultOfferTermsRoute(params, getQueryString(props))
}

export const getQueryString = (props) => {
  if (props.urlQueryStringOverride) return props.urlQueryStringOverride

  return getUrlQueryString()
}

export const getTermsText = (t, variant) => {
  if (variant) {
    return t('a_see_discount_terms_&_conditions') || SEE_DISCOUNT
  }

  return t('a_terms_conditions') || SEE_TERMS
}
