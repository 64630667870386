import { ENABLED_COUNTRY, VALID_CONSUMER_GROUP } from './constants'

export const shouldDisplayDownloadBanner = (
  isEnabled,
  isLoading,
  country,
  consumerGroup
) => {
  return (
    isEnabled &&
    !isLoading &&
    country === ENABLED_COUNTRY &&
    consumerGroup === VALID_CONSUMER_GROUP
  )
}
