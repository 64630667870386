export const DESCRIPTIONS = {
  studentbeans:
    "For instant access to this discount simply register and verify your student status with Student Beans. **It's free!**",
  gradbeans:
    "If you've graduated within 5 years, you can continue to benefit from some of the exclusive discounts Student Beans has to offer. Simply verify your graduate status for free!",
  beansid:
    "For instant access to this discount, simply register and verify your status with Beans iD. **It's free!**"
}

export const CTA_FALLBACKS = [
  'Verify as a student with Student Beans',
  'Get your discount code',
  'Use it at checkout'
]
