/**
 * @generated SignedSource<<708a900bebd73a510fac0d23d3dceffa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "standardIssuance_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "codeBlock_brand"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "12f257c25d62b205322d7c91bcc90a9f";

module.exports = node;
