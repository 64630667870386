import beansIdLogo from '@images/inline-logo-beansid.svg'
import gradsLogo from '@images/inline-logo-grads.svg'
import studentLogo from '@images/inline-logo-studentbeans.svg'

export const POWERED_BY_FALLBACK = 'Powered by'

export const LOGOS = {
  student: {
    src: studentLogo,
    altText: 'StudentBeans'
  },
  graduate: {
    src: gradsLogo,
    altText: 'StudentBeans Grads'
  },
  beansId: {
    src: beansIdLogo,
    altText: 'Beans iD'
  }
}
