import { StyleSheet } from 'aphrodite'

import {
  mother,
  brother,
  sister,
  baby
} from '@thebeansgroup/sb-ui/style_helpers/queries'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

const base = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flex: '1 1 50%',
    margin: '0px 44px',

    ...brother({
      margin: '0px 36px'
    }),

    ...sister({
      margin: '0px 44px'
    }),

    ...baby({
      margin: '0px 20px'
    })
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%', // Fixes IE flexbox bug

    ...mother({
      maxWidth: '510px'
    })
  },

  card: {
    padding: '16px',
    margin: '0px 16px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    width: '100%',
    borderRadius: '8px',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
    background: Colours('white')
  }
})

const wrapper = (backgroundColour) =>
  StyleSheet.create({
    base: {
      display: 'flex',
      minHeight: '720px',
      textAlign: 'center',
      flexDirection: 'row',
      ...sister({
        flexDirection: 'column'
      }).andBelow()
    },

    background: {
      backgroundColor: backgroundColour
    }
  })

export default {
  base,
  wrapper
}
