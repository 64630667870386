export const COOKIE_CHECK_NAME = 'sbcookiecheck'

export const COOKIE_CHECK_LEGACY_NAME = 'sbcookiecheck_legacy'

export const COOKIE_CHECK_VALUE = 'true'

export const SEVEN_DAYS_IN_MILLISECONDS = 604800000

export const LEGACY_AUTH_TOKEN_COOKIE_NAME = 'auth_token_legacy'

export const LOCAL_STORAGE_CHECK = 'localStorageCheck'
