import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const container = css`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
`

export const logo = css`
  width: 40px;
  border-radius: 4px;
  border: 1px solid ${Colours('grey', 100)};
`
