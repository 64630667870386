import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { Body } from '@thebeansgroup/sb-ui/typography'

import { useEditorData } from '@hooks/use_editor_context'

import styles from './styles'

const OfferSubtitle = (props) => {
  const [subtitleColour, setSubtitleColour] = useState(props.textColour)
  const [subtitleText, setSubtitleText] = useState(props?.offer?.subtitle)

  const { editorState } = useEditorData()

  useEffect(() => {
    if (editorState) {
      setSubtitleColour(editorState.colourState?.subtitle?.value)
      setSubtitleText(editorState.textState?.subtitleText?.value)
    }
  }, [editorState])

  if (!subtitleText) return null

  return (
    <div className={css(styles.wrapper)}>
      <Body
        component='h2'
        variant='semibold'
        colour={subtitleColour}
      >
        {subtitleText}
      </Body>
    </div>
  )
}

OfferSubtitle.propTypes = {
  textColour: PropTypes.string,
  offer: PropTypes.object.isRequired
}

OfferSubtitle.defaultProps = {
  textColour: Colours('grey', 500)
}

export default createFragmentContainer(OfferSubtitle, {
  offer: graphql`
    fragment offerSubtitle_offer on Offer {
      subtitle
    }
  `
})
