import { prependHttp } from '@helpers/url'

import { REDEMPTION_TYPES } from './constants'

export const hasAffiliateLinkOverride = (props) => {
  return !!props.connectOfferPage?.customOfferData?.issuanceOverrideUrl
}

export const getAffiliateLink = (props, link = '') => {
  if (!hasAffiliateLinkOverride(props)) return link

  return prependHttp(
    props?.connectOfferPage?.customOfferData?.issuanceOverrideUrl
  )
}

export const offerIsNoCode = (offer) => {
  return offer?.redemptionType === REDEMPTION_TYPES.NO_CODE
}
