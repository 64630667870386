import { css } from '@emotion/react'

export const wrapper = (variant) => css`
  width: 100%;
  max-width: ${variant ? '100%' : '310px'};
`

export const poweredBy = css`
  margin: 16px 0;
`
