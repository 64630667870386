import { StyleSheet } from 'aphrodite'

import { sister } from '@thebeansgroup/sb-ui/style_helpers/queries'

const base = StyleSheet.create({
  issued: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '24px',

    ...sister({
      marginBottom: '20px'
    }).andBelow()
  }
})

export default {
  base
}
