import PropTypes from 'prop-types'
import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import BrandDetails from '@components/brand_details'
import OfferDescription from '@components/offer_description'
import OfferSubtitle from '@components/offer_subtitle'
import OfferTitle from '@components/offer_title'
import PreCodeDescription from '@components/pre_code_description'

const StandardIntro = ({
  templateConfig,
  viewer,
  offer,
  brand,
  shouldDisplayNewTemplate,
  country,
  slug,
  page
}) => {
  const renderBrandDetails = () => {
    if (!shouldDisplayNewTemplate) return null

    return <BrandDetails brand={brand} />
  }

  const renderDescription = () => {
    if (shouldDisplayNewTemplate) {
      return (
        <PreCodeDescription
          brand={brand}
          country={country}
          slug={slug}
        />
      )
    }

    return (
      <React.Fragment>
        <OfferSubtitle
          {...templateConfig?.subtitle}
          offer={offer}
        />
        <OfferDescription
          {...templateConfig?.description}
          viewer={viewer}
          offer={offer}
          brand={brand}
          page={page}
        />
      </React.Fragment>
    )
  }

  const renderTitle = () => {
    return (
      <OfferTitle
        {...templateConfig?.title}
        offer={offer}
        brand={brand}
        shouldDisplayNewTemplate={shouldDisplayNewTemplate}
        page={page}
      />
    )
  }

  return (
    <React.Fragment>
      {renderBrandDetails()}
      {renderTitle()}
      {renderDescription()}
    </React.Fragment>
  )
}

StandardIntro.propTypes = {
  templateConfig: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  shouldDisplayNewTemplate: PropTypes.bool,
  page: PropTypes.string
}

StandardIntro.defaultProps = {
  page: '',
  shouldDisplayNewTemplate: false
}

export default createFragmentContainer(StandardIntro, {
  viewer: graphql`
    fragment standardIntro_viewer on AccountsViewer {
      ...offerDescription_viewer
    }
  `,
  offer: graphql`
    fragment standardIntro_offer on Offer {
      ...offerTitle_offer
      ...offerDescription_offer
      ...offerSubtitle_offer
    }
  `,
  brand: graphql`
    fragment standardIntro_brand on Brand {
      ...offerTitle_brand
      ...offerDescription_brand
      ...preCodeDescription_brand
      ...brandDetails_brand
    }
  `
})
