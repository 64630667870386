import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'

import ErrorAlert from '@components/error_alert'

const IssuanceErrorAlert = ({ t }) => (
  <ErrorAlert
    title={t('d_oh_no')}
    body={t('m_something_went_wrong_try_again_soon')}
  />
)

IssuanceErrorAlert.propTypes = {
  t: PropTypes.func.isRequired
}

export default withI18n(IssuanceErrorAlert)
