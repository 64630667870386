/**
 * @generated SignedSource<<b5f5ef25f06cc2cea3c483efe64b2017>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "codeBlock_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "e28f480a3d01aaae9a54d57330f0ccb3";

module.exports = node;
