import PropTypes from 'prop-types'
import { useRef } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { IMPRESSION_CLICK_SCHEMA_URI } from '@src/constants/impression_tracking'
import useImpressionTracking from '@src/hooks/use_impression_tracking'
import { unstructured } from '@src/tracker'

import {
  BUTTON_TEST_ID,
  CLICK_TO_REVEAL_CODE,
  REVEAL_CODE_NOW
} from './constants'
import * as styles from './styles'

const RevealCodeButton = ({ onClick, connectOfferPage, t }) => {
  const buttonRef = useRef(null)

  const { id, type, version } = connectOfferPage.offer.impressionContent

  const { impressionId } = useImpressionTracking(
    {
      content_id: id,
      content_version_id: version,
      content_type: type,
      content_position: 0,
      group_type: 'connect',
      group_position: 0,
      group_id: 'connect_brand_page'
    },
    buttonRef
  )

  const handleClick = () => {
    onClick()
    if (process.env.IMPRESSION_TRACKING_ENABLED) {
      unstructured(IMPRESSION_CLICK_SCHEMA_URI, {
        impression_id: impressionId
      })
    }
  }

  return (
    <div css={styles.container}>
      <button
        css={styles.button}
        onClick={handleClick}
        data-testid={BUTTON_TEST_ID}
        ref={buttonRef}
      >
        {t('a_reveal_code_now') || REVEAL_CODE_NOW}
        <span css={styles.peel}>
          <span
            css={styles.code}
            aria-hidden='true'
          >
            {CLICK_TO_REVEAL_CODE}
          </span>
        </span>
      </button>
    </div>
  )
}

RevealCodeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  connectOfferPage: PropTypes.shape({
    offer: PropTypes.shape({
      impressionContent: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        version: PropTypes.number.isRequired
      })
    })
  }).isRequired,
  t: PropTypes.func.isRequired
}

export default createFragmentContainer(RevealCodeButton, {
  connectOfferPage: graphql`
    fragment revealCodeButton_connectOfferPage on ConnectOfferPage {
      offer {
        impressionContent {
          id
          version
          type
        }
      }
    }
  `
})
