import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'
import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { Body } from '@thebeansgroup/sb-ui/typography'
import Callout from '@thebeansgroup/ui_typography/callout'

import { isConsumerGroupStudent } from '@src/helpers'
import { offerIsNoCode } from '@src/helpers/issuance'

import Markdown from '@components/markdown'
import { useEditorData } from '@hooks/use_editor_context'

import { CTA_FALLBACKS } from './constants'
import { getCtaCopy, getOfferDescription, isMultilineText } from './helpers'
import styles from './styles'

const OfferDescription = (props) => {
  const [bodyTextColour, setBodyTextColour] = useState(props.textColour)
  const [editorDescription, setEditorDescription] = useState(
    props?.offer?.description
  )

  const { editorState } = useEditorData()

  useEffect(() => {
    if (editorState) {
      setBodyTextColour(editorState.colourState?.bodyText?.value)
      setEditorDescription(editorState.textState?.descriptionText?.value)
    }
  }, [editorState])

  const renderNoCodeCTA = () => {
    if (props?.viewer?.isLoggedIn) return null

    if (
      !offerIsNoCode(props?.offer) &&
      isConsumerGroupStudent(props.offer?.consumerGroup)
    ) {
      return null
    }

    const { t, offer, brand } = props

    return (
      <Callout component='h4'>
        <Markdown>
          {getOfferDescription(
            t,
            offer?.consumerGroup,
            offer?.title,
            brand?.name
          )}
        </Markdown>
      </Callout>
    )
  }

  const renderCodeCta = () => {
    if (props?.viewer?.isLoggedIn) return null

    if (offerIsNoCode(props?.offer)) return null

    if (!isConsumerGroupStudent(props?.offer?.consumerGroup)) return null

    const { t } = props

    return (
      <Body
        component='div'
        colour={bodyTextColour}
      >
        <ol className={css(styles.list)}>
          {CTA_FALLBACKS.map((_, index) => (
            <Callout
              component='div'
              colour={bodyTextColour}
              key={index}
            >
              <li>
                <span className={css(styles.listItemMarker)}>{index + 1}.</span>{' '}
                <Markdown>{getCtaCopy(t, index)}</Markdown>
              </li>
            </Callout>
          ))}
        </ol>
      </Body>
    )
  }

  const renderDescription = () => {
    const currentDescription = props?.offer?.description

    if (!currentDescription && !editorDescription) return ''

    let description = (
      <Markdown>{editorDescription ?? currentDescription}</Markdown>
    )
    if (!isMultilineText(currentDescription)) {
      description = <p>{description}</p>
    }

    return (
      <Body
        component='div'
        colour={bodyTextColour}
      >
        {description}
      </Body>
    )
  }

  return (
    <div className={css(styles.wrapper)}>
      {renderDescription()}
      {renderNoCodeCTA()}
      {renderCodeCta()}
    </div>
  )
}

OfferDescription.propTypes = {
  textColour: PropTypes.string,
  viewer: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  page: PropTypes.string
}

OfferDescription.defaultProps = {
  textColour: Colours('grey', 500),
  page: ''
}

export default createFragmentContainer(withI18n(OfferDescription), {
  viewer: graphql`
    fragment offerDescription_viewer on AccountsViewer {
      isLoggedIn
    }
  `,
  offer: graphql`
    fragment offerDescription_offer on Offer {
      description
      consumerGroup
      title
      redemptionType
      uid
    }
  `,
  brand: graphql`
    fragment offerDescription_brand on Brand {
      name
    }
  `
})
