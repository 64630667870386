/**
 * @generated SignedSource<<61799f4c27c8a4f2c09cc18351eb439f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "standardWrapper_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closedConsumerGroup",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "footer_offer"
    }
  ],
  "type": "Offer",
  "abstractKey": null
};

node.hash = "52e29ecc674773dc2a1b63a1fe894b82";

module.exports = node;
