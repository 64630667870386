import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'
import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Title1 from '@thebeansgroup/ui_typography/title1'
import Title2 from '@thebeansgroup/ui_typography/title2'
import Title3 from '@thebeansgroup/ui_typography/title3'

import BoostedDetails from '@components/boosted_details'
import { useEditorData } from '@hooks/use_editor_context'

import { getOfferTitle } from './helpers'
import * as styles from './styles'

const OfferTitle = (props) => {
  const [titleColour, setTitleColour] = useState(props.textColour)
  const [brandText, setBrandText] = useState(props?.brand?.name)
  const [offerText, setOfferText] = useState(props?.offer?.title)

  const { editorState } = useEditorData()

  useEffect(() => {
    if (editorState) {
      setTitleColour(editorState?.colourState?.title.value)
      setBrandText(editorState?.textState?.brandName?.value)
      setOfferText(editorState?.textState?.discount?.value)
    }
  }, [editorState])

  const renderBoostedLabel = () => {
    return (
      <BoostedDetails
        offer={props.offer}
        page={props.page}
      />
    )
  }

  const renderPreCodeTitle = () => {
    if (!props.shouldDisplayNewTemplate) return null

    return (
      <Fragment>
        <div css={styles.preCodeTitleMobile}>
          <Title1
            component='h1'
            isMobile
            colour={titleColour}
          >
            {getOfferTitle(props, brandText, offerText)}
          </Title1>
        </div>

        <div css={styles.preCodeTitleDesktop}>
          <Title3
            colour={titleColour}
            component='h1'
          >
            {getOfferTitle(props, brandText, offerText)}
          </Title3>
        </div>
      </Fragment>
    )
  }

  const renderTitle = () => {
    if (props.shouldDisplayNewTemplate) return null

    return (
      <Fragment>
        <div css={styles.desktopTitle}>
          <Title1
            component='h1'
            colour={titleColour}
          >
            {getOfferTitle(props, brandText, offerText)}
          </Title1>
        </div>

        <div css={styles.mobileTitle}>
          <Title2
            component='h1'
            colour={titleColour}
          >
            {getOfferTitle(props, brandText, offerText)}
          </Title2>
        </div>
      </Fragment>
    )
  }

  return (
    <div css={styles.wrapper}>
      {renderBoostedLabel()}
      {renderTitle()}
      {renderPreCodeTitle()}
    </div>
  )
}

OfferTitle.propTypes = {
  textColour: PropTypes.string,
  offer: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  shouldDisplayNewTemplate: PropTypes.bool,
  page: PropTypes.string
}

OfferTitle.defaultProps = {
  textColour: Colours('grey', 500),
  shouldDisplayNewTemplate: false,
  page: ''
}

export default createFragmentContainer(withI18n(OfferTitle), {
  offer: graphql`
    fragment offerTitle_offer on Offer {
      boosted
      boostedExtraText
      boostedWasText
      expired
      expiringSoon
      expiryMessage
      discountEndDate
      title
    }
  `,
  brand: graphql`
    fragment offerTitle_brand on Brand {
      name
    }
  `
})
