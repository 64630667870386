import PropTypes from 'prop-types'
import React from 'react'

import { FULL, HALF, STAR_TYPE_IMAGES } from './constants'
import * as styles from './styles'

const AppStarRating = ({ starType }) => {
  return (
    <div css={styles.star}>
      <img
        role='presentation'
        src={STAR_TYPE_IMAGES[starType]}
        width='12'
        height='12'
      />
    </div>
  )
}

AppStarRating.defaultProps = {
  starType: FULL
}

AppStarRating.propTypes = {
  starType: PropTypes.oneOf([FULL, HALF]).isRequired
}

export default AppStarRating
