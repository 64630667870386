import { css } from 'aphrodite'
import Link from 'found/Link'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import { withI18n } from '@thebeansgroup/_i18n'
import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { Subhead } from '@thebeansgroup/sb-ui/typography'

import ImplementationContext from '@src/contexts/implementation'

import { FLAGSHIP_EVENT_TERMS_CLICK } from '@constants/flagship'
import { useFlagshipEvent } from '@hooks/use_flagship_event'

import { getTermsText, getTermsUrl } from './helpers'
import styles from './styles'

const TermsLink = (props) => {
  const { sendFlagshipEvent } = useFlagshipEvent()
  const implementation = useContext(ImplementationContext)

  const handleClick = () => {
    sendFlagshipEvent(FLAGSHIP_EVENT_TERMS_CLICK)
  }

  return (
    <Link
      onClick={handleClick}
      className={css(styles.base)}
      to={getTermsUrl(props, implementation)}
    >
      <Subhead
        component='p'
        colour={Colours('grey', 500)}
      >
        {getTermsText(props.t, props.variant)}
      </Subhead>
    </Link>
  )
}

TermsLink.defaultProps = {
  variant: false
}

TermsLink.propTypes = {
  country: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  urlQueryStringOverride: PropTypes.string,
  variant: PropTypes.bool
}

export default withI18n(TermsLink)
