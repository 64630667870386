/**
 * @generated SignedSource<<f307719940b19d2b20d86f76cad66091>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "standardIntro_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "offerDescription_viewer"
    }
  ],
  "type": "AccountsViewer",
  "abstractKey": null
};

node.hash = "d80a92ec51faf60af94126d4f3536a93";

module.exports = node;
