import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'

import StrikeThroughPercentage from '@components/strikethrough_percentage'

import {
  shouldRenderBoostedDetails,
  shouldRenderEndingTag,
  shouldRenderWasText,
  getOfferEndingText,
  shouldRenderBoosted
} from './helpers'
import * as styles from './styles'

const BoostedDetails = ({ offer, t, page, variant }) => {
  if (!shouldRenderBoosted(page, offer)) return null

  const renderEndingTag = () => {
    return <div css={styles.ending}>{getOfferEndingText(offer, t)}</div>
  }

  const renderWasText = () => {
    return (
      <div css={styles.boosted}>
        <StrikeThroughPercentage text={offer.boostedWasText} />
      </div>
    )
  }

  const renderContent = () => {
    if (shouldRenderBoostedDetails(offer)) {
      return (
        <div css={styles.content}>
          {renderEndingTag()}
          {renderWasText()}
        </div>
      )
    }

    if (shouldRenderWasText(offer)) {
      return renderWasText()
    }

    if (shouldRenderEndingTag(offer)) {
      return renderEndingTag()
    }
  }

  return <div css={styles.container(variant)}>{renderContent()}</div>
}

BoostedDetails.defaultProps = {
  variant: false
}

BoostedDetails.propTypes = {
  t: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  variant: PropTypes.bool,
  offer: PropTypes.shape({
    boosted: PropTypes.bool.isRequired,
    boostedExtraText: PropTypes.string,
    boostedWasText: PropTypes.string,
    expired: PropTypes.bool,
    expiringSoon: PropTypes.bool,
    expiryMessage: PropTypes.string,
    discountEndDate: PropTypes.string
  }).isRequired
}

export default withI18n(BoostedDetails)
