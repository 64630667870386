import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'
import { Colours } from '@thebeansgroup/ui_style_helpers'
import Footnote from '@thebeansgroup/ui_typography/footnote'

import { getText } from './helpers'
import * as styles from './styles'

const RegistrationTime = ({ t, textColour }) => {
  return (
    <div css={styles.container}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18ZM12.293 13.293C12.037 13.293 11.781 13.195 11.586 13L9.293 10.707C9.105 10.52 9 10.265 9 10V6C9 5.448 9.447 5 10 5C10.553 5 11 5.448 11 6V9.586L13 11.586C13.391 11.977 13.391 12.609 13 13C12.805 13.195 12.549 13.293 12.293 13.293Z'
          fill={textColour}
        />
      </svg>
      <Footnote colour={textColour}>{getText(t)}</Footnote>
    </div>
  )
}

RegistrationTime.defaultProps = {
  textColour: Colours('grey', 500)
}

RegistrationTime.propTypes = {
  t: PropTypes.func.isRequired,
  textColour: PropTypes.string.isRequired
}

export default withI18n(RegistrationTime)
