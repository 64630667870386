import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'
import Callout from '@thebeansgroup/ui_typography/callout'

import TermsLink from '@components/terms_link'

import { getFirstListItem, getSecondListItem, getTitle } from './helpers'
import * as styles from './styles'

const PreCodeDescription = ({ t, brand, slug, country }) => {
  return (
    <div css={styles.container}>
      <Callout fontWeight='semibold'>{getTitle(t)}</Callout>
      <ol css={styles.list}>
        <li>{getFirstListItem(t)}</li>
        <li>{getSecondListItem(t, brand.name)}</li>
      </ol>
      <TermsLink
        slug={slug}
        country={country}
        variant
      />
    </div>
  )
}

PreCodeDescription.propTypes = {
  t: PropTypes.func.isRequired,
  brand: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  slug: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired
}

export default createFragmentContainer(withI18n(PreCodeDescription), {
  brand: graphql`
    fragment preCodeDescription_brand on Brand {
      name
    }
  `
})
