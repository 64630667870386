/**
 * @generated SignedSource<<2629ae27e6c92ce9a60394c3d94bac22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "brandDetails_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "44dc4994639524ac4dbe269e4bcf43c4";

module.exports = node;
