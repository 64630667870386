import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

import Button from '@components/button'
import RevealCodeButton from '@components/reveal_code_button'
import { brandColourDefaultProp } from '@helpers/index'

import { REVEAL_CODE_FALLBACK } from './constants'
import { getCtaText, handleAffiliateLink } from './helpers'
import * as styles from './styles'

const IssuanceButton = (props) => {
  const handleClick = () => {
    handleAffiliateLink(props)
    props.onClick()
  }

  const renderBlurredCodeBlock = () => {
    if (props.isNoCode) return null

    return <div css={styles.blurredCode}>{REVEAL_CODE_FALLBACK}</div>
  }

  const renderBlurredIssuanceButton = () => {
    return (
      <Button
        buttonColour={Colours('violet', 500)}
        buttonTextColour={props.buttonTextColour}
        onClick={handleClick}
        connectOfferPage={props.connectOfferPage}
      >
        {getCtaText(
          props.t,
          props.connectOfferPage?.offer?.cta,
          props.isNoCode
        )}
      </Button>
    )
  }

  if (props.shouldDisplayNewTemplate) {
    return (
      <RevealCodeButton
        onClick={handleClick}
        connectOfferPage={props.connectOfferPage}
        t={props.t}
      />
    )
  }

  return (
    <div css={styles.wrapper}>
      {renderBlurredCodeBlock()}
      {renderBlurredIssuanceButton()}
    </div>
  )
}

IssuanceButton.propTypes = {
  buttonColour: PropTypes.string,
  buttonTextColour: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isNoCode: PropTypes.bool.isRequired,
  shouldDisplayNewTemplate: PropTypes.bool,
  t: PropTypes.func.isRequired
}

IssuanceButton.defaultProps = {
  shouldDisplayNewTemplate: false,
  buttonColour: brandColourDefaultProp(),
  buttonTextColour: Colours('white', 300)
}

export default createFragmentContainer(withI18n(IssuanceButton), {
  connectOfferPage: graphql`
    fragment issuanceButton_connectOfferPage on ConnectOfferPage {
      ...button_connectOfferPage
      ...revealCodeButton_connectOfferPage
      offer {
        cta
      }
    }
  `
})
