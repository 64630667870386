import root from 'window-or-global'

import { BEANS_BRAND_BEANS_ID, BEANS_BRAND_STUDENT_BEANS } from '@src/constants'
import { CODE_REVEAL_PAGE } from '@src/constants/impression_tracking'
import { shouldShowMultiOfferPage } from '@src/helpers'
import { offerIsNoCode } from '@src/helpers/issuance'

import {
  GRADUATE_CONSUMER_GROUP,
  STUDENT_CONSUMER_GROUP
} from '@components/template/constants'
import {
  sendUserCopiedCodeEvent,
  sendCodeIssuanceErrorEvent
} from '@helpers/inapp'
import { getUserAgent, isWebview } from '@helpers/webview'

export const handleOnCopyCodeClick = (code) => {
  sendUserCopiedCodeEvent(code)
}

export const handleCodeIssuanceError = (error) => {
  sendCodeIssuanceErrorEvent(error)
}

export const onHasIssued = (setUrlQueryString) => {
  setUrlQueryString(root?.location?.search)
}

export const shouldIssueOffersInASingleWindow = (offer) => {
  const userAgent = getUserAgent()

  return isWebview(userAgent) && offer.redemptionType !== 'no_code'
}

const getEarliestActiveOfferForEachConsumerGroup = (allActiveOffers) => {
  const consumerGroupFilteredOffers = []

  allActiveOffers.forEach((offer) => {
    const doesConsumerGroupAlreadyExistInArray =
      consumerGroupFilteredOffers.find(
        (filteredOffer) => filteredOffer.consumerGroup === offer.consumerGroup
      )

    if (!doesConsumerGroupAlreadyExistInArray) {
      consumerGroupFilteredOffers.push(offer)
    }
  })

  return consumerGroupFilteredOffers
}

export const getActiveOffers = (offers) =>
  offers.filter((offer) => offer?.status !== 'scheduled' && offer?.enabled)

export const getActiveOfferIds = (offers) => {
  const allActiveOffers = getActiveOffers(offers)

  const consumerGroupFilteredOffers =
    getEarliestActiveOfferForEachConsumerGroup(allActiveOffers)

  const arrayOfFilteredOfferIds = consumerGroupFilteredOffers.map(
    (filteredOffer) => filteredOffer.uid
  )

  return arrayOfFilteredOfferIds
}

export const getOfferFromOffersById = (selectedOfferId, offers) => {
  const activeOfferInArray = offers.filter(
    (offer) => offer?.uid === selectedOfferId
  )
  return activeOfferInArray[0]
}

export const getBeansBrand = (offers) => {
  const allActiveOffers = getActiveOffers(offers)
  const consumerGroupFilteredOffers =
    getEarliestActiveOfferForEachConsumerGroup(allActiveOffers)

  const isStudentBeansBrand = consumerGroupFilteredOffers.every((offer) => {
    const arrayStudentBeansBrandConsumerGroups = [
      STUDENT_CONSUMER_GROUP,
      GRADUATE_CONSUMER_GROUP
    ]
    return arrayStudentBeansBrandConsumerGroups.includes(
      offer.consumerGroup?.toLowerCase()
    )
  })

  return isStudentBeansBrand ? BEANS_BRAND_STUDENT_BEANS : BEANS_BRAND_BEANS_ID
}

export const dedupeOffers = (offers) => {
  const dedupedOffers = []

  offers.forEach((offer) => {
    const offerUidAlreadyAdded = dedupedOffers.find(
      (dedupedOffer) => dedupedOffer?.uid === offer?.uid
    )

    if (!offerUidAlreadyAdded) {
      dedupedOffers.push(offer)
    }
  })

  return dedupedOffers
}

export const shouldShowMultiConnect = (selectedCCG, selectedOfferId) => {
  if (selectedOfferId || selectedCCG) return false

  return true
}

export const shouldShowMultiOfferView = (
  selectedCCG,
  selectedOfferId,
  multiOffer
) => {
  if (!shouldShowMultiOfferPage(multiOffer)) return false

  if (!selectedCCG || selectedOfferId) return false

  return true
}

export const shouldRenderNewCodeRevealTemplate = (page, offer) => {
  if (offerIsNoCode(offer)) return false

  if (page !== CODE_REVEAL_PAGE) return false

  return true
}
