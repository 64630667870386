import { css } from '@emotion/react'

export const container = (direction) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${direction};
  gap: 4px;
`

export const logo = css`
  height: auto;
  display: inline-flex;
`
