export const ANDROID_APP_STORE_URL_GB =
  'https://play.google.com/store/apps/details?id=com.studentbeans.studentbeans&hl=gb&referrer=utm_source%3Dstudentbeans%26utm_medium%3Dconnect_code_redeemed_page'
export const ANDROID_APP_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.studentbeans.studentbeans&hl={countryCode}&referrer=utm_source%3Dstudentbeans%26utm_medium%3Dconnect_code_redeemed_page'

export const IOS_APP_STORE_URL_GB =
  'https://itunes.apple.com/gb/app/apple-store/id994482161?pt=10031819&ct=studentbeans-connect_code_redeemed_page&mt=8'
export const IOS_APP_STORE_URL =
  'https://itunes.apple.com/{countryCode}/app/apple-store/id994482161?pt=10031819&ct=studentbeans-connect_code_redeemed_page&mt=8'

export const ANDROID = 'android'
export const IOS = 'ios'
