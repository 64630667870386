export const ENABLED_COUNTRY = 'uk'

export const VALID_CONSUMER_GROUP = 'student'

export const TITLE = 'Get more with the Student Beans app'
export const DESCRIPTION =
  'Exclusive app deals, in-store offers, save your faves and more. Enjoy it all on the app.'

export const APP_DOWNLOAD_LINK_LOCATION = 'connect_after_code_redemption'

export const QR_CODE_ALT = 'Download the Student Beans app via QR code'
