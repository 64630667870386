import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const container = css`
  display: flex;
  margin-top: 20px;
  width: 100%;
`
export const button = css`
  border-radius: 4px;
  height: 40px;
  width: 100%;
  background: ${Colours('violet', 500)};
  position: relative;
  display: block;
  color: ${Colours('white')};
  text-align: center;
  cursor: pointer;
  min-height: 2.5rem;
  line-height: 2.5rem;
  outline: 0;
  padding: 0;
  border: 0;
  clear: right;
  transition: background 0.3s ease;

  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 2.625rem;
    height: 2.875rem;
    top: 0.125rem;
    right: 0.375rem;
    z-index: 2;
    background-image: linear-gradient(
      38deg,
      ${Colours('violet', 100)} 0,
      ${Colours('violet', 100)} 49%,
      transparent 50%,
      transparent 100%
    );
    background-repeat: no-repeat;
    background-position: 0 0;
    transform: rotate(15deg) translate(-0.5rem, -0.1875rem) scaleX(1);
    border-radius: 0 0 0 0.3125rem;
  }

  &:hover {
    background: ${Colours('violet', 400)};
  }
`

export const peel = css`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 3.125rem;
  height: 100%;
  overflow: hidden;
  padding-right: 12px;
  direction: rtl;
  border-radius: 0 4px 4px;
  text-align: right;
  color: ${Colours('violet', 200)};
  background: ${Colours('white')};
  border: 2px dashed;
`

export const code = css`
  color: ${Colours('grey', 500)};
  font-weight: bold;
  letter-spacing: 4px;
`
