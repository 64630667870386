import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const container = (variant) => css`
  display: flex;

  justify-content: ${variant ? 'flex-start' : 'center'};
  align-items: center;
`

export const boosted = css`
  color: ${Colours('alertRed', 300)};
  font-size: 15px;
`

export const ending = css`
  color: ${Colours('alertRed', 300)};
  font-size: 15px;
  font-weight: 600;
`

export const content = css`
  display: flex;
  flex-direction: column;
`
