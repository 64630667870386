import { encode } from 'js-base64'
import root from 'window-or-global'

import { isBrowserAndroidChrome } from '@src/helpers/browser'

import { canSetCookies } from '@helpers/auth'
import { isConsumerGroupGraduate } from '@helpers/index'
import { getRouteParams, getRouteQueryParamValue } from '@helpers/routing'
import {
  getUrlParamKeyValues,
  getUrlParamStringFromObject,
  getUrlParams,
  getStbOfferPath
} from '@helpers/url'

import {
  DEFAULT_MEDIUM,
  LOGIN_BUTTON_FALLBACK,
  AUTH_BUTTON_CLICK,
  CHINESE_TRADITIONAL_CODE,
  MACAU_CODE
} from '../constants'

export const getLanguageOverride = (match) => {
  return match?.location?.query?.language || ''
}

export const getAuthUrlParams = (
  props,
  implementation,
  acceptsThirdPartyCookie
) => {
  const { country } = getRouteParams(props)
  const language = getLanguageOverride(props.match)
  const { clientId, brand, offer } = props.connectOfferPage
  const redirect = getRedirectUri(
    props,
    implementation,
    language,
    acceptsThirdPartyCookie
  )

  const confirmLanguage =
    language === CHINESE_TRADITIONAL_CODE ? MACAU_CODE : language

  let urlObject = {
    country,
    response_type: 'code',
    client_id: clientId,
    redirect_uri: encodeURIComponent(redirect),
    medium: getMedium(implementation),
    auth_path: 'sign-up',
    language: confirmLanguage,
    offer_brand_logo: encodeURIComponent(brand.logo),
    brand_name: encodeURLParam(brand.name),
    offer_title: encodeURLParam(offer.title),

    ...getAccountsLogoToggle(props)
  }
  const consumerGroup = { consumer_group: props.consumerGroup }
  if (process.env.CLOSED_CONSUMER_GROUP) {
    urlObject = { ...urlObject, ...consumerGroup }
  }
  return getUrlParamStringFromObject(urlObject)
}

export const getMedium = (implementation) => {
  if (implementation) return `connect-${implementation}`

  return DEFAULT_MEDIUM
}

export const getAccountsLogoToggle = (props) => {
  if (props?.hideLogo) return { popup: 1 }

  return {}
}

export const getRedirectUri = (
  props,
  implementation,
  language,
  acceptsThirdPartyCookie
) => {
  const { country, slug } = getRouteParams(props)
  const hostPageParams = props.match?.location?.query?.host_page_params

  const params = {
    country,
    slug,
    implementation,
    language,
    is_express: props.isExpress,
    host_page_params: hostPageParams
  }

  const stbOfferPath = getStbOfferPath()
  if (stbOfferPath && acceptsThirdPartyCookie) {
    params.stb_offer_path = encodeURIComponent(stbOfferPath)
  }

  const offerUid = getOfferUidParam(props)
  if (offerUid) params.offer_uid = offerUid

  return `${process.env.REDIRECT_URI}?${getUrlParamStringFromObject(params)}`
}

const getOfferUidParam = (props) => {
  if (!process.env.CLOSED_CONSUMER_GROUP_V1) return null

  return getRouteQueryParamValue(props, 'offer_uid')
}

export const getAuthUrl = (props, implementation, acceptsThirdPartyCookie) => {
  return `${process.env.ACCOUNTS_URL}/oauth/authorize?${getAuthUrlParams(
    props,
    implementation,
    acceptsThirdPartyCookie
  )}`
}

export const openAuthUrl = (
  props,
  implementation,
  acceptsThirdPartyCookie,
  isExpress
) => {
  if (isExpress) {
    return root.open(getAuthUrl(props, 'express', true), '_self')
  }

  return root.open(
    getAuthUrl(props, implementation, acceptsThirdPartyCookie),
    acceptsThirdPartyCookie ? '_self' : '_top'
  )
}

export const handleClick = (
  clickHandler,
  handleSuccess,
  handleFailure,
  sendFlagshipEvent
) => {
  sendFlagshipEvent(AUTH_BUTTON_CLICK)

  // Calling handleFailure will send the user to accounts then hosted connect (first-party journey)
  // If we can't set third-party cookies then we send the user down the first-party journey.
  // This will take them to accounts outside of the iFrame, and then hosted connect.

  // If the user is on Android Chrome then we also send the user down the first-party journey
  // This is because Android Chrome gets stuck in a loop on the third-party journey.
  // This is a temporary solution for the Android Chrome issue.
  // This will be resolved with the migration to web.
  // https://wearepion.atlassian.net/browse/MER-3686

  if (!canSetCookies() || isBrowserAndroidChrome()) return handleFailure()

  clickHandler(handleSuccess, handleFailure)
}

export const getFirstPartyConnectUrl = () => {
  const params = getUrlParams()
  const paramKeyValues = getUrlParamKeyValues(params)
  delete paramKeyValues.stb_offer_path
  paramKeyValues.first_party = true
  const urlParams = getUrlParamStringFromObject(paramKeyValues)
  return `${root.location.origin}${root.location.pathname}?${urlParams}`
}

export const getButtonText = (t, consumerGroup) => {
  if (isConsumerGroupGraduate(consumerGroup)) {
    return t('a_verify_grad') || LOGIN_BUTTON_FALLBACK
  }
  return t('a_login') || LOGIN_BUTTON_FALLBACK
}

export const encodeURLParam = (string) => {
  return encodeURIComponent(encode(string))
}
