import { StyleSheet } from 'aphrodite'

const styles = ({ buttonColour, buttonTextColour }) =>
  StyleSheet.create({
    base: {
      backgroundColor: buttonColour,
      color: buttonTextColour,
      display: 'block',
      borderRadius: '5px',
      fontSize: '16px',
      height: '40px',
      lineHeight: '40px',
      width: '100%',
      padding: '0 20px',
      border: '2px solid transparent',
      textDecoration: 'none'
    }
  })

export default styles
