import { css } from 'aphrodite'
import PropTypes from 'prop-types'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { Title3, Callout } from '@thebeansgroup/sb-ui/typography'

import styles from './styles'

const ErrorAlert = ({ title, body }) => {
  return (
    <div className={css(styles.wrapper)}>
      <Title3
        component='h2'
        colour={Colours('wildBlackberry', 400)}
      >
        {title}
      </Title3>

      <Callout
        component='p'
        colour={Colours('wildBlackberry', 400)}
      >
        {body}
      </Callout>
    </div>
  )
}

ErrorAlert.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
}

export default ErrorAlert
