import { useCallback, useEffect, useRef, useState } from 'react'

import { ONE_SECOND, FIFTY_PERCENT_VIEWABLE } from './constants'
import { shouldObserve } from './helpers'

const useImpressionView = (ref, handleImpressionView, impressionId) => {
  const [hasBeenViewed, setHasBeenViewed] = useState(false)
  const observer = useRef(null)
  const timerRef = useRef(null)

  const cleanObserver = () => {
    if (observer.current) {
      observer.current.disconnect()
    }
  }

  const handleImpressionViewTimeout = useCallback(() => {
    handleImpressionView(impressionId)
    setHasBeenViewed(true)
    cleanObserver()
  }, [impressionId, handleImpressionView])

  const handleIntersectionChange = useCallback(
    ([entry]) => {
      const isElementIntersecting = entry.isIntersecting

      if (!isElementIntersecting) {
        clearTimeout(timerRef.current)
        return
      }

      timerRef.current = setTimeout(handleImpressionViewTimeout, ONE_SECOND)
    },
    [handleImpressionViewTimeout]
  )

  useEffect(() => {
    if (!shouldObserve(ref, hasBeenViewed)) {
      return
    }

    cleanObserver()

    const ob = (observer.current = new window.IntersectionObserver(
      handleIntersectionChange,
      { threshold: FIFTY_PERCENT_VIEWABLE }
    ))

    ob.observe(ref.current)

    return () => {
      cleanObserver()
    }
  }, [ref]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useImpressionView
