import {
  CODE_COPIED_FALLBACK,
  COPY_CODE_FALLBACK,
  ONE_SECOND
} from './constants'

export function copyToClipboard(code, setCodeCopied) {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCodeCopied(true)
      })
      .catch(() => {})
  } else {
    const textArea = document.createElement('textarea')
    textArea.value = code
    textArea.style.position = 'fixed'
    textArea.style.opacity = '0'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      document.execCommand('copy')

      setCodeCopied(true)
    } catch (err) {
      return new Error('Unable to copy code to clipboard')
    }

    document.body.removeChild(textArea)
  }
}

export function getCtaText(t) {
  return t('a_copy_code_CTA') || COPY_CODE_FALLBACK
}

export function getCtaCopiedText(t) {
  return t('w_copied') || CODE_COPIED_FALLBACK
}

export const handleCopiedContent = (codeCopied, setCodeCopied) => {
  if (codeCopied) {
    const timeout = setTimeout(() => {
      setCodeCopied(false)
    }, ONE_SECOND)

    return () => {
      clearTimeout(timeout)
    }
  }
}
