import qs from 'querystring'

import cookies from 'js-cookie'
import root from 'window-or-global'

import { AUTH_TOKEN_EXPIRY, AUTH_TOKEN_NAME } from '@src/client/constants'

import { isDevelopmentEnv } from '@helpers/index'

import {
  COOKIE_CHECK_NAME,
  COOKIE_CHECK_LEGACY_NAME,
  COOKIE_CHECK_VALUE,
  SEVEN_DAYS_IN_MILLISECONDS,
  LOCAL_STORAGE_CHECK
} from './constants'

export const canSetCookies = () => {
  try {
    setCookieCheck()
    return !!(
      cookies.get(COOKIE_CHECK_NAME) || cookies.get(COOKIE_CHECK_LEGACY_NAME)
    )
  } catch (e) {
    return false
  }
}

export const setCookieCheck = () => {
  const now = new Date(Date.now()).getTime()
  const expires = new Date(now + SEVEN_DAYS_IN_MILLISECONDS).toUTCString()
  const secure = getCookieSecureFlag()

  document.cookie = `${COOKIE_CHECK_LEGACY_NAME}=${COOKIE_CHECK_VALUE};path=/;expires=${expires};${secure}`
  document.cookie = `${COOKIE_CHECK_NAME}=${COOKIE_CHECK_VALUE};path=/;expires=${expires};${secure};samesite=none`
}

export const getCookieSecureFlag = () => (isDevelopmentEnv() ? '' : 'secure')

export const resolveHasDocumentStorageAccess = (
  hasAccess,
  handleSuccess,
  handleFailure
) => {
  if (hasAccess) {
    handleSuccess()
  } else {
    handleFailure()
  }
}

export const handleHasDocumentStorageAccess = (
  handleSuccess,
  handleFailure
) => {
  try {
    const promise = document.hasStorageAccess()
    return promise.then(
      (hasAccess) =>
        resolveHasDocumentStorageAccess(
          hasAccess,
          handleSuccess,
          handleFailure
        ),
      () => handleFailure()
    )
  } catch (error) {
    handleSuccess()
  }
}

export const getParsedQueryParams = () => {
  const queryParams = root.location.search.substring(1)
  const parsedParams = qs.parse(queryParams)
  return parsedParams
}

export const isLocalStorageAvailable = () => {
  try {
    localStorage.setItem(LOCAL_STORAGE_CHECK, LOCAL_STORAGE_CHECK)
    localStorage.removeItem(LOCAL_STORAGE_CHECK)
    return true
  } catch {
    return false
  }
}

export const setAuthToken = (token, expires) => {
  if (!isLocalStorageAvailable()) return

  const tokenHasChanged = hasTokenChanged(AUTH_TOKEN_NAME, token)

  if (token && tokenHasChanged) {
    localStorage.setItem(AUTH_TOKEN_NAME, token)
    localStorage.setItem(AUTH_TOKEN_EXPIRY, expires.getTime())
  }
}

export const hasTokenChanged = (newToken) => {
  const existingToken = localStorage.getItem(AUTH_TOKEN_NAME)

  return newToken !== existingToken
}
