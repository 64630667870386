import {
  formatConsumerGroupForTranslations,
  getBeansBrandFromConsumerGroup
} from '../../../helpers'
import { CTA_FALLBACKS, DESCRIPTIONS } from '../constants'

export function isMultilineText(text) {
  if (!text) return false

  return (text.match(/\n/g) || []).length > 0
}

export const getOfferDescription = (t, consumerGroup, title, brandName) => {
  const formattedConsumerGroup =
    formatConsumerGroupForTranslations(consumerGroup)
  const beansBrandName =
    getBeansBrandFromConsumerGroup(consumerGroup)?.toLowerCase()
  const translation = t(`m_connect_description_${formattedConsumerGroup}`, {
    title,
    brand: brandName
  })
  return translation || DESCRIPTIONS[beansBrandName]
}

export const getCtaCopy = (t, i) => {
  if (i === 0) return t('m_verify_as_a_student') || CTA_FALLBACKS[0]

  if (i === 1) return t('m_get_discount_code') || CTA_FALLBACKS[1]

  return t('m_use_it_at_checkout') || CTA_FALLBACKS[2]
}
