import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'

import ErrorAlert from '@components/error_alert'

const NoCodesAlert = ({ t }) => (
  <ErrorAlert
    title={t('m_no_codes_left')}
    body={t('m_replenished_ASAP')}
  />
)

NoCodesAlert.propTypes = {
  t: PropTypes.func.isRequired
}

export default withI18n(NoCodesAlert)
