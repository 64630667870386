/**
 * @generated SignedSource<<1621fa6130c9fb9663722e2f8f5f0042>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "standardIntro_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "offerTitle_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "offerDescription_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "preCodeDescription_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "brandDetails_brand"
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "918d4d651ffa08f480642f0aa6c5a0b7";

module.exports = node;
