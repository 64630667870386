import PropTypes from 'prop-types'

import { DEL_TEST_ID, PERCENTAGE_REGEX } from './constants'
import * as styles from './styles'

const StrikeThroughPercentage = ({ text }) => {
  const match = text.match(PERCENTAGE_REGEX)

  if (match) {
    const splitIndex = match.index
    const percentText = match[0]
    const beforeText = text.substring(0, splitIndex)
    const afterText = text.substring(splitIndex + percentText.length)

    return (
      <span>
        {beforeText}
        <span
          data-testid={DEL_TEST_ID}
          css={styles.lineThrough}
        >
          {percentText}
        </span>
        {afterText}
      </span>
    )
  }

  return <span>{text}</span>
}

StrikeThroughPercentage.propTypes = {
  text: PropTypes.string.isRequired
}

export default StrikeThroughPercentage
