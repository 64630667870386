/**
 * @generated SignedSource<<b176c7216dd56c014132c078d2ff5db7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "brandImages_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "0fd9a856476be15ef2d4000644103482";

module.exports = node;
