import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const container = css`
  text-align: left;
`

export const list = css`
  padding: 8px 0 8px 0;
  margin: 0;
  list-style-position: inside;

  li {
    color: ${Colours('grey', 400)};
  }

  li::marker {
    font-weight: bold;
  }
`
