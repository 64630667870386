/**
 * @generated SignedSource<<b1055081724bc111c8184b8f2b355768>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "standardWrapper_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "footer_viewer"
    }
  ],
  "type": "AccountsViewer",
  "abstractKey": null
};

node.hash = "a6b3407125e38215edc3ed6887d09841";

module.exports = node;
