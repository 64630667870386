import PropTypes from 'prop-types'
import React from 'react'

import { FLAGSHIP_EVENT_APP_DOWNLOAD } from '@src/constants/flagship'

import { useFlagshipEvent } from '@hooks/use_flagship_event'

import { ANDROID, IOS } from './constants'
import { getAppLinkData, trackAppLinkClick } from './helpers'
import * as styles from './styles'

const AppLink = ({ platform, country, locationOnPage }) => {
  const { sendFlagshipEvent } = useFlagshipEvent()

  const { href, src, translationFallback } = getAppLinkData(country, platform)

  const handleClick = (event) => {
    event.preventDefault()
    sendFlagshipEvent(FLAGSHIP_EVENT_APP_DOWNLOAD)
    location.assign(href)
    trackAppLinkClick(platform, country, locationOnPage)
  }

  return (
    <a
      data-testid={`${platform}-link`}
      onClick={handleClick}
      href={href}
      css={styles.appBlockImage}
    >
      <img
        src={src}
        alt={translationFallback}
      />
    </a>
  )
}

AppLink.propTypes = {
  country: PropTypes.string.isRequired,
  locationOnPage: PropTypes.string.isRequired,
  platform: PropTypes.oneOf([ANDROID, IOS]).isRequired
}

export default AppLink
