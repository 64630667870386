/**
 * @generated SignedSource<<31d0636ce95c2e317c0eff2763e90d2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "issuanceButton_connectOfferPage",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "button_connectOfferPage"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "revealCodeButton_connectOfferPage"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cta",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ConnectOfferPage",
  "abstractKey": null
};

node.hash = "7bde84ba8595bcf6601c095aa096f36d";

module.exports = node;
