import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'

import OfferErrorHandler from '@components/offer_error_handler'
import OfferPageViewTracker from '@components/offer_page_view_tracker'
import PageHead from '@components/page_head'

import { showMultiOfferPage } from '../../helpers'

import { trackOnPageLoad } from './helpers'

const OfferPageContentWrapper = (props) => (
  <OfferErrorHandler
    loading={props?.loading}
    relayError={props?.relayError}
    loaderText={props?.loaderText}
    connectOfferPage={props?.connectOfferPage}
  >
    <OfferPageViewTracker
      viewer={props?.viewer}
      offer={props?.connectOfferPage?.offer}
      countryCode={props?.countryCode}
      connectOfferPage={props?.connectOfferPage}
      onLoad={trackOnPageLoad.bind(
        this,
        props?.connectOfferPage?.uid,
        props?.connectOfferPage?.domains
      )}
      selectedOfferId={props?.selectedOfferId}
      isMultiCCGConnect={showMultiOfferPage(props?.connectOfferPage?.offers)}
    />
    <PageHead connectOfferPage={props.connectOfferPage} />
    {props?.children}
  </OfferErrorHandler>
)

OfferPageContentWrapper.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  countryCode: PropTypes.string.isRequired,
  connectOfferPage: PropTypes.object,
  viewer: PropTypes.object,
  relayError: PropTypes.object,
  loaderText: PropTypes.string,
  selectedOfferId: PropTypes.string,
  isMultiOfferConnect: PropTypes.bool
}

export default createFragmentContainer(withI18n(OfferPageContentWrapper), {
  viewer: graphql`
    fragment offerPageContentWrapper_viewer on AccountsViewer {
      ...offerPageViewTracker_viewer
    }
  `,
  connectOfferPage: graphql`
    fragment offerPageContentWrapper_connectOfferPage on ConnectOfferPage {
      ...offerErrorHandler_connectOfferPage
      ...offerPageViewTracker_connectOfferPage
      ...pageHead_connectOfferPage
      uid
      domains
      offer {
        ...offerPageViewTracker_offer
      }
      offers {
        enabled
        status
        consumerGroup
      }
    }
  `
})
