import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import Callout from '@thebeansgroup/ui_typography/callout'

import * as styles from './styles'

const BrandDetails = ({ brand }) => {
  return (
    <div css={styles.container}>
      <img
        css={styles.logo}
        alt={brand.name}
        src={brand.logo}
      />

      <Callout
        component='h4'
        fontWeight='semibold'
      >
        {brand.name}
      </Callout>
    </div>
  )
}

BrandDetails.propTypes = {
  brand: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}

export default createFragmentContainer(BrandDetails, {
  brand: graphql`
    fragment brandDetails_brand on Brand {
      logo
      name
    }
  `
})
