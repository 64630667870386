import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'

import { EDITOR_MESSAGE_ID } from './constants'

export const EditorDataContext = createContext({})

export const UseEditorProvider = ({ children, value }) => {
  const [editorState, setEditorState] = useState(value)

  useEffect(() => {
    window.addEventListener('message', ({ data }) => {
      if (data.id === EDITOR_MESSAGE_ID) {
        setEditorState(data)
      }
    })

    return () => window.removeEventListener('message', {})
  }, [editorState])

  return (
    <EditorDataContext.Provider value={{ editorState }}>
      {children}
    </EditorDataContext.Provider>
  )
}

UseEditorProvider.defaultProps = {
  value: null
}

UseEditorProvider.propTypes = {
  children: PropTypes.any.isRequired,
  value: PropTypes.object
}
