import { OFFER_PAGE } from '@src/constants/impression_tracking'

import { ENDS_IN_DAYS, ENDS_TODAY, ENDS_TOMORROW, MS_IN_HR } from './constants'

export const shouldRenderEndingTag = (offer) => {
  if (!offer.expiryMessage) return false

  if (!offer.discountEndDate) return false

  if (!offer.expiringSoon) return false

  return hoursUntilExpiry(offer.discountEndDate) >= 0
}

const hoursUntilExpiry = (offerExpiry) => {
  const now = new Date()
  const offerExpiryDate = new Date(offerExpiry)
  return Math.ceil((offerExpiryDate - now) / MS_IN_HR)
}

export const shouldRenderWasText = (offer) => {
  return !!offer.boostedWasText
}

export const shouldRenderBoostedDetails = (offer) => {
  return shouldRenderEndingTag(offer) && shouldRenderWasText(offer)
}

const hoursUntilTomorrow = () => {
  const now = new Date()
  const tomorrow = new Date(now)
  tomorrow.setDate(now.getDate() + 1)
  tomorrow.setHours(0, 0, 0, 0)

  return Math.ceil((tomorrow - now) / MS_IN_HR)
}

export const getOfferEndingText = (offer, t) => {
  const hoursAfterTodayWhenOfferExpires =
    hoursUntilExpiry(offer?.discountEndDate) - hoursUntilTomorrow()

  if (hoursAfterTodayWhenOfferExpires <= 0) {
    return t('d_expires_today') || ENDS_TODAY
  }

  if (hoursAfterTodayWhenOfferExpires <= 24) {
    return t('d_expires_tomorrow') || ENDS_TOMORROW
  }

  const daysLeft = Math.ceil(hoursAfterTodayWhenOfferExpires / 24)

  return (
    t('d_expires_in_days', {
      days: daysLeft
    }) || ENDS_IN_DAYS`${daysLeft}`
  )
}

export const shouldRenderBoosted = (page, offer) => {
  if (!offer?.boostedWasText && !offer?.expiryMessage) return false

  return page === OFFER_PAGE
}
