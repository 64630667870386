import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'

import Markdown from '@components/markdown'

import { getTimeOrDate } from './helpers'
import * as styles from './styles'

const NextCodeAlert = ({ endDate, t }) => {
  if (!endDate) return null

  const readableEndDate = getTimeOrDate(endDate, t)
  const boldMDEndDate = `**${readableEndDate}**`

  return (
    <div
      css={styles.alert}
      role='status'
    >
      <Markdown>{t('m_single_use_code', { time: boldMDEndDate })}</Markdown>
    </div>
  )
}

NextCodeAlert.propTypes = {
  endDate: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
}

export default withI18n(NextCodeAlert)
