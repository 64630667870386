import { getStbOfferPath, prependHttp } from '@helpers/url'
import { OFFER_PATH_QUERY_STRING_KEY } from '@helpers/url/constants'

export const getOfferUrl = () => {
  const offerUrl = getStbOfferPath()
  if (!isValidOfferUrl(offerUrl)) return ''

  return prependHttp(offerUrl)
}

export const isValidOfferUrl = (offerUrl) => {
  return !!offerUrl && offerUrl.split(OFFER_PATH_QUERY_STRING_KEY).length < 2
}

export const isPreCodePage = () => {
  return !(
    window.location.search.includes('redeem_online') ||
    window.location.search.includes('redeem_instore')
  )
}
