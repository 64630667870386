import { useFlagship, HitType, EventCategory } from '@flagship.io/react-sdk'

export const useFlagshipEvent = () => {
  const { hit } = useFlagship()

  const sendFlagshipEvent = (name, description, value) => {
    const event = {
      type: HitType.EVENT,
      category: EventCategory.ACTION_TRACKING,
      action: name,
      label: description,
      value
    }
    hit.send(event)
  }

  return { sendFlagshipEvent }
}
