/**
 * @generated SignedSource<<997776772ee297ec64d6706828e28f59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "offerDescription_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLoggedIn",
      "storageKey": null
    }
  ],
  "type": "AccountsViewer",
  "abstractKey": null
};

node.hash = "1e37421a3c83b880a5f29466e257817d";

module.exports = node;
