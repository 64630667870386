import { css } from 'aphrodite'
import PropTypes from 'prop-types'

import styles from './styles'

const ImageHeader = (props) => {
  const { imageSrc, imageHeight, imageWidth, imageDescription } = props

  return (
    <div className={css(styles.wrapper.base)}>
      <div className={css(styles.background(imageHeight).container)}>
        <div className={css(styles.background(imageHeight).placeholder)}>
          <img
            className={css(styles.background(imageHeight).image)}
            src={imageSrc}
            alt={imageDescription}
          />
        </div>
      </div>
      <div
        className={css(styles.image(imageSrc, imageHeight, imageWidth).base)}
      />
    </div>
  )
}

ImageHeader.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageHeight: PropTypes.string.isRequired,
  imageWidth: PropTypes.string.isRequired,
  imageDescription: PropTypes.string.isRequired
}

export default ImageHeader
