import { StyleSheet } from 'aphrodite'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'

const styles = StyleSheet.create({
  wrapper: {
    padding: '16px 20px',
    backgroundColor: Colours('wildBlackberry', '100'),
    borderRadius: '5px',
    maxWidth: '355px',
    margin: '16px'
  }
})

export default styles
