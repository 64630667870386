import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'
import CopyInput from '@thebeansgroup/sb-ui/form_fields/copy_input'

import { FLAGSHIP_EVENT_COPY_CODE } from '@src/constants/flagship'
import { unstructured } from '@src/tracker'

import { useFlagshipEvent } from '@hooks/use_flagship_event'

import { IMPRESSION_CLICK_SCHEMA_URI } from '../../constants/impression_tracking'
import useImpressionTracking from '../../hooks/use_impression_tracking'
import Union from '../../images/union.svg'

import Input from './children/input'
import { CODE_COPIED_FALLBACK } from './constants'
import { copyToClipboard, getCtaText, handleCopiedContent } from './helpers'
import styles from './styles'

export const Code = ({
  code,
  t,
  onCopyCodeClick,
  onHasIssued,
  connectOfferPage
}) => {
  const inputRef = useRef(null)
  const { sendFlagshipEvent } = useFlagshipEvent()
  const [codeCopied, setCodeCopied] = useState(false)

  const { id, type, version } = connectOfferPage.offer.impressionContent

  const { impressionId } = useImpressionTracking(
    {
      content_id: id,
      content_version_id: version,
      content_type: type,
      content_position: 0,
      group_type: 'connect',
      group_position: 0,
      group_id: 'connect_code_issue'
    },
    inputRef
  )

  useEffect(() => {
    handleCopiedContent(codeCopied, setCodeCopied)
  }, [codeCopied])

  useEffect(() => {
    if (onHasIssued) onHasIssued()
  })

  useEffect(() => {
    if (code) {
      window?.parent?.postMessage(
        {
          id: 'connect-code-issued',
          code
        },
        '*'
      )
    }
  }, [code])

  const handleClick = () => {
    if (process.env.IMPRESSION_TRACKING_ENABLED) {
      unstructured(IMPRESSION_CLICK_SCHEMA_URI, {
        impression_id: impressionId
      })
    }

    if (onCopyCodeClick) onCopyCodeClick(code)
    sendFlagshipEvent(FLAGSHIP_EVENT_COPY_CODE)
    setCodeCopied(renderCodeCopied())
  }

  const handleBlurredButtonClick = () => {
    setCodeCopied(true)
    copyToClipboard(code)
    return handleClick()
  }

  const renderCopyInput = () => {
    if (process.env.BLURRED_ISSUANCE_ENABLED) return null

    return (
      <CopyInput
        value={code}
        InputComponent={Input}
        copySuccessText={t('a_copy')}
        copyButtonText={t('a_copy')}
        copyButtonAriaLabel={t('a_copy')}
        onClick={handleClick}
      />
    )
  }

  const renderCtaContent = () => {
    if (!codeCopied) return getCtaText(t)

    return renderCodeCopied()
  }

  const renderCodeCopied = () => {
    return (
      <div className={css(styles.codeCopied)}>
        <img
          src={Union}
          height={18}
          width={16}
        />
        <span className={css(styles.codeText)}>{CODE_COPIED_FALLBACK}</span>
      </div>
    )
  }

  const renderBlurredIssuance = () => {
    if (!process.env.BLURRED_ISSUANCE_ENABLED) return null

    return (
      <>
        <div className={css(styles.code)}>{code}</div>
        <button
          onClick={handleBlurredButtonClick}
          className={css(styles.button)}
        >
          {renderCtaContent()}
        </button>
      </>
    )
  }

  return (
    <div
      className={css(styles.container)}
      ref={inputRef}
    >
      {renderCopyInput()}
      {renderBlurredIssuance()}
    </div>
  )
}

export default createFragmentContainer(withI18n(Code), {
  connectOfferPage: graphql`
    fragment code_connectOfferPage on ConnectOfferPage {
      offer {
        impressionContent {
          id
          version
          type
        }
      }
    }
  `
})

Code.propTypes = {
  code: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onCopyCodeClick: PropTypes.func,
  onHasIssued: PropTypes.func
}
