export const isDateToday = (inputDate) => {
  const todaysDate = new Date()

  return inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
}

export const getTimeOrDate = (dateString, t) => {
  const date = new Date(dateString)

  if (isDateToday(date)) return getReadableEndTime(dateString, t)

  return `${getReadableEndTime(dateString, t)}, ${date.toLocaleDateString(
    undefined,
    {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }
  )}`
}

export const getReadableEndTime = (dateString, t) => {
  const date = new Date(dateString)
  const hours = date.getHours()
  const mins = pad(date.getMinutes())

  return calculateTime(hours, mins, t)
}

export const formatTimeZeroHours = (hours, mins, t) => {
  hours += 12
  return t('w_am', { hours, mins })
}

export const formatTimeTwelveHours = (hours, mins, t) => {
  return t('w_pm', { hours, mins })
}

export const formatTime = (hours, mins, t) => {
  if (hours > 12) {
    hours -= 12
    return t('w_pm', { hours, mins })
  }
  return t('w_am', { hours, mins })
}

export const calculateTime = (hours, mins, t) => {
  if (hours === 0) return formatTimeZeroHours(hours, mins, t)

  if (hours === 12) return formatTimeTwelveHours(hours, mins, t)

  return formatTime(hours, mins, t)
}

const pad = (str) => str.toString().padStart(2, '0')
