import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'

import { unstructured } from '@src/tracker'

import { IMPRESSION_CLICK_SCHEMA_URI } from '@constants/impression_tracking'
import { brandColourDefaultProp } from '@helpers/index'
import useImpressionTracking from '@hooks/use_impression_tracking'

import { getElement } from './helpers'
import styles from './styles'

const Button = (props) => {
  const buttonRef = useRef(null)

  const { id, type, version } = props.connectOfferPage.offer.impressionContent

  const { impressionId } = useImpressionTracking(
    {
      content_id: id,
      content_version_id: version,
      content_type: type,
      content_position: 0,
      group_type: 'connect',
      group_position: 0,
      group_id: 'connect_brand_page'
    },
    buttonRef
  )

  const handleClick = () => {
    props.onClick()
    if (process.env.IMPRESSION_TRACKING_ENABLED) {
      unstructured(IMPRESSION_CLICK_SCHEMA_URI, {
        impression_id: impressionId
      })
    }
  }

  const Element = getElement(props)
  return (
    <Element
      data-testid='button'
      className={css(styles(props).base)}
      href={props.href}
      ref={buttonRef}
      onClick={handleClick}
      type='button'
    >
      {props.children}
    </Element>
  )
}

Button.propTypes = {
  buttonColour: PropTypes.string,
  buttonTextColour: PropTypes.string,
  children: PropTypes.any.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func
}

Button.defaultProps = {
  buttonColour: brandColourDefaultProp(),
  buttonTextColour: Colours('white', 300)
}

export default createFragmentContainer(Button, {
  connectOfferPage: graphql`
    fragment button_connectOfferPage on ConnectOfferPage {
      offer {
        impressionContent {
          id
          version
          type
        }
      }
    }
  `
})
