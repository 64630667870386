import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React from 'react'

import {
  shouldRenderLoadingIssuanceTop,
  shouldRenderLoadingIssuanceBottom
} from './helpers'
import styles from './styles'

const LoadingIssuance = (props) => {
  const renderLoadingIssuanceTop = (props) => {
    if (!shouldRenderLoadingIssuanceTop(props)) return null

    return (
      <React.Fragment>
        <div
          className={css(styles.loadingBlock, styles.loadingBlockTop)}
          data-testid='LoadingBlockTop'
        />
        <div className={css(styles.loadingBlock)} />
      </React.Fragment>
    )
  }
  const renderLoadingIssuanceBottom = (props) => {
    if (!shouldRenderLoadingIssuanceBottom(props)) return null

    return (
      <div className={css(styles.loadingBlock, styles.loadingBlockBottom)}>
        <div className={css(styles.loadingBlockBottomContentContainer)}>
          <div
            className={css(
              styles.loadingBlock,
              styles.loadingBlockBottomContent
            )}
          />
          <div
            className={css(
              styles.loadingBlock,
              styles.loadingBlockBottomContent
            )}
          />
          <div
            className={css(
              styles.loadingBlock,
              styles.loadingBlockBottomContent
            )}
          />
        </div>
      </div>
    )
  }
  return (
    <React.Fragment>
      {renderLoadingIssuanceTop(props)}
      {renderLoadingIssuanceBottom(props)}
    </React.Fragment>
  )
}

export default LoadingIssuance

LoadingIssuance.propTypes = {
  offer: PropTypes.object
}
