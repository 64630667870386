import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'
import TextLink from '@thebeansgroup/ui_ctas/text_link_new'

export const CopyCodePrompt = ({ link, brand, t }) => {
  const brandLink = (
    <TextLink
      underlined
      href={link}
      text={t('a_brand_website', { brand })}
      target={'_parent'}
    />
  )

  return (
    <p>
      {t('a_copy_code')} {brandLink}
    </p>
  )
}

CopyCodePrompt.propTypes = {
  link: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
}

export default withI18n(CopyCodePrompt)
