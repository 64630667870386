import { StyleSheet } from 'aphrodite'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'

const styles = StyleSheet.create({
  base: {
    color: Colours('grey', 500),
    textDecoration: 'underline'
  }
})

export default styles
