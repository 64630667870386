import ratingStarHalf from '@images/rating-star-half.svg'
import ratingStar from '@images/rating-star.svg'

export const STAR_TYPE_IMAGES = {
  full: ratingStar,
  half: ratingStarHalf
}

export const FULL = 'full'
export const HALF = 'half'
