import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { storeImpressionLoad, storeImpressionView } from '@src/tracker/helpers'

import { hasImpressionTrackingSupport } from '@hooks/use_impression_listener/helpers'
import useImpressionView from '@hooks/use_impression_view'

const useImpressionTracking = (data, ref) => {
  const [impressionId] = useState(() => uuidv4())
  const hasSupport = hasImpressionTrackingSupport()

  useImpressionView(ref, storeImpressionView, impressionId)

  useEffect(() => {
    if (hasSupport) {
      storeImpressionLoad(data, impressionId)
    }
  }, [hasSupport]) // eslint-disable-line react-hooks/exhaustive-deps

  if (data && hasSupport) return { impressionId }

  return { impressionId: null }
}

export default useImpressionTracking
