/**
 * @generated SignedSource<<40ddb228f586daac955bfe933795d04f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "offerPageViewTracker_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "pageViewTracker_viewer"
    }
  ],
  "type": "AccountsViewer",
  "abstractKey": null
};

node.hash = "9abb7d33d575de31bf9aa946703d7200";

module.exports = node;
