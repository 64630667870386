import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const alert = css`
  background-color: ${Colours('violet', '50')};
  color: ${Colours('grey', '500')};
  padding: 8px;
  max-width: 355px;
  border-radius: 5px;
`
