import qrCode from '@images/app-qr-code.png'
import PropTypes from 'prop-types'
import React from 'react'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { Headline, Footnote } from '@thebeansgroup/sb-ui/typography'

import AppLink from '@components/app_link'
import AppStarRating from '@components/app_star_rating'
import { HALF } from '@components/app_star_rating/constants'
import { ANDROID, IOS } from '@constants/app_download'
import { useFlag } from '@hooks/use_flag'

import {
  TITLE,
  DESCRIPTION,
  APP_DOWNLOAD_LINK_LOCATION,
  QR_CODE_ALT
} from './constants'
import { shouldDisplayDownloadBanner } from './helpers'
import * as styles from './styles'

const AppDownloadBanner = ({ country, consumerGroup }) => {
  const [isAppDownloadBannerEnabled, isAppDownloadBannerLoading] = useFlag(
    'js-loki-connect-app-dl',
    false
  )

  if (
    !shouldDisplayDownloadBanner(
      isAppDownloadBannerEnabled.getValue(),
      isAppDownloadBannerLoading,
      country,
      consumerGroup
    )
  ) {
    return null
  }

  const renderStars = () => {
    return (
      <div css={styles.stars}>
        <AppStarRating />
        <AppStarRating />
        <AppStarRating />
        <AppStarRating />
        <AppStarRating starType={HALF} />
      </div>
    )
  }

  const renderAppRating = (ratingNumber, ratingCount) => {
    return (
      <div css={styles.appRatingContainer}>
        <div css={styles.appRating}>
          <Footnote
            colour={Colours('grey', 500)}
            component='p'
          >
            {ratingNumber}
          </Footnote>
          {renderStars()}
        </div>
        <div>
          <Footnote
            colour={Colours('grey', 350)}
            component='p'
          >
            {`(${ratingCount}K reviews)`}
          </Footnote>
        </div>
      </div>
    )
  }

  const renderAppleBlock = () => {
    return (
      <div css={[styles.appBlock, styles.appleBlock]}>
        <AppLink
          country={country}
          locationOnPage={APP_DOWNLOAD_LINK_LOCATION}
          platform={IOS}
        />
        {renderAppRating(4.8, 33)}
      </div>
    )
  }

  const renderGooglePlayBlock = () => {
    return (
      <div css={styles.appBlock}>
        <AppLink
          country={country}
          locationOnPage={APP_DOWNLOAD_LINK_LOCATION}
          platform={ANDROID}
        />
        {renderAppRating(4.7, 17.1)}
      </div>
    )
  }

  const renderStoreSection = () => {
    return (
      <div data-testid='app-promo-mweb'>
        {renderAppleBlock()}
        {renderGooglePlayBlock()}
      </div>
    )
  }

  return (
    <div css={styles.wrapper}>
      <div>
        <div css={styles.starsDesktop}>{renderStars()}</div>
        <Headline
          colour={Colours('grey', 500)}
          component='h2'
        >
          {TITLE}
        </Headline>
        <div css={styles.description}>
          <Footnote
            colour={Colours('grey', 500)}
            component='p'
          >
            {DESCRIPTION}
          </Footnote>
        </div>
      </div>
      <div
        data-testid='app-promo-desktop'
        css={styles.qrCode}
      >
        <img
          css={styles.qrCodeImage}
          src={qrCode}
          alt={QR_CODE_ALT}
        />
      </div>
      {renderStoreSection()}
    </div>
  )
}

AppDownloadBanner.propTypes = {
  country: PropTypes.string.isRequired
}

export default AppDownloadBanner
