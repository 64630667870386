/**
 * @generated SignedSource<<81af9d8ca6d47cdb26caaefcfddd3c81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "codeBlock_connectOfferPage",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "code_connectOfferPage"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomConnectOfferDate",
      "kind": "LinkedField",
      "name": "customOfferData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "issuanceOverrideUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ConnectOfferPage",
  "abstractKey": null
};

node.hash = "b128452b478eea0c031a50ecb55c119a";

module.exports = node;
