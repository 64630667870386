import { StyleSheet } from 'aphrodite'

import { baby } from '@thebeansgroup/sb-ui/style_helpers/queries'

const styles = StyleSheet.create({
  wrapper: {
    width: '100%', // Fixes IE flexbox bug
    marginBottom: '16px',

    ...baby({
      marginBottom: '20px'
    })
  }
})

export default styles
