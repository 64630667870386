/**
 * @generated SignedSource<<2dfdcdb67754271a6cd2b3d394fc9605>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "standardIssuance_connectOfferPage",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authButton_connectOfferPage"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "codeBlock_connectOfferPage"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "issuanceButton_connectOfferPage"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomConnectOfferDate",
      "kind": "LinkedField",
      "name": "customOfferData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "issuanceOverrideUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ConnectOfferPage",
  "abstractKey": null
};

node.hash = "6569d17de0ed950023b9b3bf1f1a04e0";

module.exports = node;
