import { css } from '@emotion/react'
import appBannerBgDesktop from '@images/app-banner-bg-desktop.png'
import appBannerBg from '@images/app-banner-bg.png'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { brotherAndAbove } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const wrapper = css`
  margin-top: 20px;
  padding: 16px;
  text-align: left;
  border-radius: 8px;
  color: ${Colours('grey', 500)};
  background-color: ${Colours('grey', 25)};
  background-image: url(${appBannerBg});
  background-size: 122.5px 135px;
  background-position: right bottom;
  background-repeat: no-repeat;

  ${brotherAndAbove} {
    display: flex;
    align-items: center;
    padding-left: 55px;
    background-image: url(${appBannerBgDesktop});
    background-size: 52px 96px;
    background-position: left bottom;
  }
`

export const description = css`
  padding-right: 14%;
  margin-bottom: 8px;

  ${brotherAndAbove} {
    margin-bottom: 0;
  }
`

export const stars = css`
  display: flex;
`

export const appBlock = css`
  display: flex;
  align-items: center;

  ${brotherAndAbove} {
    display: none;
  }
`

export const appleBlock = css`
  margin-bottom: 8px;
`

export const appRatingContainer = css`
  display: flex;
  flex-direction: column;
`
export const appRating = css`
  display: flex;
  align-items: center;
  margin-top: 2px;

  > p {
    line-height: 13px !important;
    margin-right: 4px !important;
  }
`

export const qrCode = css`
  display: none;

  ${brotherAndAbove} {
    flex-shrink: 0;
    flex-grow: 0;
    display: block;
    line-height: 0;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid ${Colours('grey', 100)};
    background-color: ${Colours('white')};
  }
`

export const qrCodeImage = css`
  width: 64px;
  height: 64px;
`

export const starsDesktop = css`
  display: none;

  ${brotherAndAbove} {
    display: flex;
  }
`
