import { StyleSheet } from 'aphrodite'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'

const wrapper = StyleSheet.create({
  base: {
    position: 'relative'
  }
})

const background = (height) =>
  StyleSheet.create({
    container: {
      overflow: 'hidden',
      position: 'absolute',
      left: 0,
      right: 0,
      height
    },
    placeholder: {
      height: '100%',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: `${Colours('grey', 400)}`
    },
    image: {
      margin: '-15px',
      maxWidth: '200%',
      height: '200%',
      filter: 'blur(15px)'
    }
  })

const image = (src, height, width) =>
  StyleSheet.create({
    base: {
      position: 'relative',
      backgroundImage: `url("${src}")`,
      height,
      maxWidth: width,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      margin: '0 auto'
    }
  })

export default {
  wrapper,
  background,
  image
}
