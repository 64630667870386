import { getAffiliateLink } from '@src/helpers/issuance'

import { OPEN_SITE_FALLBACK } from './constants'

export const getCtaText = (t, ctaText, isNoCode) => {
  if (isNoCode) return ctaText

  return t('a_reveal_code_open_site') || OPEN_SITE_FALLBACK
}

export const handleAffiliateLink = (props) => {
  if (props.isExpress) {
    const affiliateTab = window.open(getAffiliateLink(props), '_blank')

    setTimeout(() => {
      affiliateTab.close()
    }, 2000)
  }
}
