import {
  BRAND_WEBSITE,
  COPY_CODE,
  HOW_TO_REDEEM,
  REVEAL_YOUR_DISCOUNT_CODE
} from './constants'

export const getTitle = (t) => {
  return t('d_how_to_redeem') || HOW_TO_REDEEM
}

export const getFirstListItem = (t) => {
  return t('m_reveal_your_discount_code') || REVEAL_YOUR_DISCOUNT_CODE
}

export const getSecondListItem = (t, brandName) => {
  if (t('a_copy_code')) {
    return `${t('a_copy_code')} ${t('a_brand_website', { brand: brandName })}`
  }

  return `${COPY_CODE} ${brandName} ${BRAND_WEBSITE}`
}
